import React from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptCompanyRight from "./EsgGptCompanyRight";
import EsgGptChatField from "../EsgGptChatField";
import EsgGptCompanyList from "../EsgGptCompanyList";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import EsgGptUpdatedList from "../esgGptAddNewCompany/EsgGptUpdatedList";
import GptTour, {
  TourContent,
  tourStyle,
} from "../../../screen/gptTour/GptTour";
import { setSignupStepNo } from "../../../../redux/actions/login/loginAction";

const companySteps = [
  {
    selector: '[data-tut="esg-navigator"]',
    content: () => (
      <TourContent
        heading={"ESG Navigator"}
        content={
          "Use this panel to effortlessly navigate through the list of companies, or switch to the “Chats” tab where you can ask questions regarding anything ESG."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="companies-tab"]',
    content: () => (
      <TourContent
        heading={"Companies Tab"}
        content={
          "Unlock a wealth of ESG insights at your fingertips with our Companies Library, providing comprehensive details on a diverse range of companies' environmental, social, and governance practices."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="add-newCompany"]',
    content: () => (
      <TourContent
        heading={"Add New Company"}
        content={
          "Expand our ESG Wise database by seamlessly requesting details for additional companies, ensuring that your unique business landscape is comprehensively covered for a holistic ESG perspective."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="company-listing"]',
    content: () => (
      <TourContent
        heading={"Company Listings"}
        content={
          "Discover ESG insights at a glance with our Company listings, providing a comprehensive overview of diverse businesses' environmental, social, and governance profiles."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="insight-guideline"]',
    content: () => (
      <TourContent
        heading={"Feedback & Policy"}
        content={
          "Your valuable feedback is welcome, and you can review our terms and conditions here."
        }
      />
    ),
    style: tourStyle,
  },
];

function EsgGptCompanyProfiles(props) {
  const { controversyLinks } = useSelector((state) => state?.riskDashboard);
  const { organizations, newOrganization } = useSelector(
    (state) => state.esgGptReducer
  );

  const isCompanyTourCompleted = JSON.parse(
    localStorage.getItem("company_tour_completed")
  );

  return (
    <>
      {!isCompanyTourCompleted && (
        <GptTour steps={companySteps} resource={"company"} />
      )}
      <EsgGptlayout
        EsgCompanyList={
          organizations?.favorites?.length > 0 ? (
            <EsgGptUpdatedList />
          ) : (
            <EsgGptCompanyList />
          )
        }
        login={props.login}
        controversyLinks={controversyLinks}
        logOut={props.logOut}
      >
        <EsgGptCompanyRight />
        {!newOrganization?.loader && <EsgGptChatField />}
      </EsgGptlayout>
    </>
  );
}

export default EsgGptCompanyProfiles;
