import {
  SET_SHOW_NOTIFICATIONS,
  SET_SHOW_SETTINGS,
} from "../../../constants/esgGptConstant";

// --------------------- payload actions ---------------------
// payload for Settings Drawer
export const setShowSettings = (payload) => {
  return {
    type: SET_SHOW_SETTINGS,
    payload,
  };
};
// payload for Notifications Drawer
export const setShowNotifications = (payload) => {
  return {
    type: SET_SHOW_NOTIFICATIONS,
    payload,
  };
};

// --------------------- dispatch actions ---------------------
