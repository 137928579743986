import { useState } from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";

import PlayIcon from "../../../../../images/play-icon.svg";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ArrowForwardIosRounded";
import YouTube from "react-youtube";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  accordonMain: {
    boxShadow: "none",
    borderBottom: "1px solid #A1BFDF",
  },
  expandIcon: {
    width: "18px",
    color: "#3374B9",
    transform: "rotate(0deg)",
    transition: "transform 0.3s ease",
  },
  rotated: {
    transform: "rotate(-90deg)",
  },
  accordionTitle: {
    width: "100%",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  },
  accordionContent: {
    width: "100%",
  },
}));

const DrawerMain = ({ onClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // YouTube video options
  const opts = {
    height: "248",
    width: "440",
    playerVars: {
      autoplay: 0,
    },
  };

  // YouTube video ID
  const videoId = "skRSfDfMbSM";

  const accordionData = [
    {
      _id: "panel1",
      title: "How can I access ESG data on specific companies?",
      content:
        "ESGWise provides users with access to ESG data on over 100 companies. Users can inquire about specific ESG metrics, allowing them to gain insights into the environmental, social, and governance practices of individual companies.",
    },
    {
      _id: "panel2",
      title: "How does ESGWise assist in decision-making?",
      content:
        "ESGWise delivers actionable insights by evaluating ESG risk, strengths, and areas for improvement from diverse data sources. By providing users with company-specific insights, ESGWise facilitates informed decision-making regarding investment, risk management, and corporate governance.",
    },
    {
      _id: "panel3",
      title: "Is peer benchmarking available with ESGWise?",
      content:
        "Yes, ESGWise Premium offers exclusive access to company-specific peer analysis, enabling users to benchmark their performance against industry competitors. This feature provides valuable insights into how a company’s ESG practices compare to those of its peers, helping users identify areas for improvement.",
    },
    {
      _id: "panel4",
      title: "What is ESGWise and how does it simplify ESG analysis?",
      content:
        "ESGWise is a cutting-edge generative AI platform designed to streamline Environmental, Social, and Governance (ESG) analysis. It achieves this by consolidating a vast array of data from diverse sources, leveraging advanced machine learning algorithms to process and comprehend complex sustainability information. ESGWise empowers users to gain comprehensive insights into ESG-related issues quickly and efficiently.",
    },
    {
      _id: "panel5",
      title: "How does ESGWise utilize AI technology for ESG analysis?",
      content:
        "ESGWise harnesses the power of advanced machine learning algorithms to analyze extensive ESG data. By processing information from various sources such as ESG reports, goals, news stories, annual reports, and insights from ESG advisors, it enhances its understanding of sustainability-related matters. This enables ESGWise to provide users with actionable insights for informed decision-making.",
    },
    {
      _id: "panel6",
      title:
        "What should I consider regarding ESGWise’s current limitations and future potential?",
      content:
        "While ESGWise provides robust ESG insights, it is continuously evolving. User feedback is essential for shaping its future development and expanding its capabilities. As ESGWise learns and adapts with each interaction, it aims to provide increasingly valuable insights for stakeholders, including investors, advisors, and beyond. Users are encouraged to join in shaping the future of ESG data accessibility and utilization by providing feedback and suggestions for improvement.",
    },
    {
      _id: "panel7",
      title:
        "How will the data entered into the chat be used for internal processing?",
      content:
        "The data entered into the chat will be used solely for internal processing purposes related to improving the functionality and performance of ESGWise. We prioritize the privacy and security of user data and adhere to strict data protection measures to ensure confidentiality and compliance with relevant regulations.",
    },
    {
      _id: "panel8",
      title:
        "Why is it important to verify data accuracy, and why can it be challenging?",
      content:
        "It’s crucial to verify the accuracy of data provided by ESGWise before making decisions based on the chat responses. While ESGWise strives for accuracy, achieving absolute certainty can be challenging due to factors like data inconsistency and quality issues. Therefore, it’s recommended to double-check facts and cross-reference information from reliable sources to ensure informed decision-making.",
    },
  ];

  return (
    <Box sx={{ padding: "10px" }}>
      {/* Video Box */}
      {/* <Box
        sx={{
          width: "100%",
          height: "235px",
          backgroundColor: "#EBF1F8",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
        {/* <YouTube videoId={videoId} opts={opts} /> */}
        {/* <img
          src={PlayIcon}
          alt="PlayIcon"
          style={{
            width: "42px",
            height: "47.12px",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
        /> */}
      {/* </Box> */}
      {/* Quick Onboarding */}
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "14px",
          paddingLeft: "10px",
          lineHeight: "24px",
          // marginTop: "1em",
        }}
      >
        <span
          onClick={() => {
            localStorage.setItem("company_tour_completed", false);
            localStorage.setItem("chat_tour_completed", false);
            localStorage.setItem("help_center", true);
            onClose();
            history.push("/esg_gpt/company_profiles");
          }}
          style={{ cursor: "pointer", color: "#3374B9" }}
        >
          Click Here{" "}
        </span>
        for Quick Onboarding Experience.
      </Typography>

      {/* Questions Box */}
      <Box sx={{ marginTop: 20 }}>
        {accordionData.map((item) => (
          <Accordion
            key={item._id}
            expanded={expanded === item._id}
            onChange={handleChange(item._id)}
            className={classes.accordonMain}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  className={`${classes.expandIcon} ${
                    expanded === item._id ? classes.rotated : ""
                  }`}
                />
              }
              aria-controls={`${item._id}-content`}
              id={`${item._id}-header`}
            >
              <Typography className={classes.accordionTitle}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.accordionContent}>
                {item.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {/* More Queries */}
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "22px",
          margin: "2em 0px",
        }}
      >
        For more queries, please
        <span style={{ cursor: "pointer", color: "#3374B9" }}> click here</span>
      </Typography>
    </Box>
  );
};

export default DrawerMain;
