import { Box, Grid, Typography } from "@material-ui/core";

// icons & logos
import sideleft from "../../../../images/sidebarLeft.png";
import sideright from "../../../../images/sidebarRight.png";
import AI from "../../../../images/AI.svg";

const LeftNotShrink = ({ classes, subCard }) => {
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        sx={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        item
        container
        justifyContent="center"
        style={{
          margin: "5% 0%",
          flexDirection: "column",
          maxWidth: "90vw",
        }}
      >
        <Box display="flex" justifyContent="flex-end" paddingRight="20px">
          <img src={sideright} alt="left" className={classes.img3} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          paddingBottom="15px"
          paddingLeft="15px"
        >
          <img
            src={AI}
            alt="user"
            style={{
              marginLeft: 50,
              marginRight: -50,
              width: "100%",
              height: "40%",
            }}
          />

          <Typography className={classes.sideBarTitle}>
            AI-powered sustainability guide
          </Typography>
          {subCard("500+ ESG Company profiles")}
          {subCard("ESG Enabled Knowledge Base")}
          {subCard("Peer & Industry ESG Performance Benchmarking")}
        </Box>
        <Box display="flex" justifyContent="flex-start" paddingLeft="16px">
          <img src={sideleft} alt="right" className={classes.img3} />
        </Box>
      </Grid>
    </Grid>
  );
};
export default LeftNotShrink;
