import { Box, Button, Typography } from "@material-ui/core";

const GrayBackgroundCard = ({
  heading,
  subHeading,
  sideHeading,
  is_premium,
  handleClosePricingDialog,
  Icon,
}) => {
  // console.log(is_premium);
  return (
    <div
      style={{
        width: "calc(100% - 40px)",
        backgroundColor: "#F6F6F6",
        display: "flex",
        flexDirection:
          sideHeading || is_premium === false || is_premium === null
            ? "row"
            : "column",
        alignItems:
          is_premium === false || is_premium === null ? "center" : "flex-start",
        marginTop: "10px",
        justifyContent: "space-between",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        style={{ marginLeft: Icon ? 10 : 0 }}
      >
        {Icon}
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 600,
            color: "#0F0F0F",
            lineHeight: "20px",
            padding: subHeading ? "15px 15px 10px 15px" : "15px",
          }}
        >
          {heading}
        </Typography>
      </Box>
      {subHeading && (
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "#676767",
            padding: "0px 15px 15px 15px",
          }}
        >
          {subHeading}
        </Typography>
      )}
      {sideHeading && (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#15314E",
            padding: "15px",
            marginLeft: "auto",
          }}
        >
          {subHeading || sideHeading}
        </Typography>
      )}
      {(is_premium === false || is_premium === null) && (
        <Button
          variant="contained"
          style={{
            color: "#1976D2",
            backgroundColor: "white",
            marginRight: "10px",
          }}
          size="small"
          onClick={() => handleClosePricingDialog(heading)}
          disableElevation={true}
        >
          View Details
        </Button>
      )}
    </div>
  );
};
export default GrayBackgroundCard;
