// HelpCenterDrawer.js
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import DrawerHeader from "./DrawerComponents/DrawerHeader";
import DrawerMain from "./DrawerComponents/DrawerMain";
import Box from "@material-ui/core/Box";

const HelpCenterDrawer = ({ open, onClose }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: "480px", height: "max-content" }}>
        {/* Header Section */}
        <DrawerHeader onClose={onClose} />

        {/* Main Content Section */}
        <DrawerMain onClose={onClose} />
      </Box>
    </Drawer>
  );
};

export default HelpCenterDrawer;
