import UNIVERSAL from "../../../config";
import { SET_NOTIFICATIONS } from "../../../constants/esgGptConstant";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";

// --------------------- payload actions ---------------------
// payload for updating user profile
export const setNotifications = (payload) => {
  return {
    type: SET_NOTIFICATIONS,
    payload,
  };
};

// --------------------- dispatch actions ---------------------
// update user profile
export const getNotifications = (token, limit = 10, skip = 0) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      limit: limit || 10,
      skip: skip || 0,
    });

    return fetch(UNIVERSAL.BASEURL + "/notifications/get_notifications", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setNotifications(responseJson?.result));
          // responseJson?.result?.favorites.length > 0
          //   ? dispatch(setNewCompanyDetails(responseJson?.result?.favorites[0]))
          //   : dispatch(
          //       setNewCompanyDetails(responseJson?.result?.repositories[0])
          //     );
        }

        dispatch(unset_loader);
      });
  };
};
