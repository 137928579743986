import React from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IconButton, Tooltip } from "@material-ui/core";

// icons
import EditGeneric from "../../../images/editGeneric.svg";
import { FiPlus } from "react-icons/fi";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { setSelectedChat } from "../../../redux/actions/gpt/esgGptAction";

const appTheme = createTheme({
  palette: {
    secondary: {
      main: "#3374B9",
    },
  },
});

export default function EsgGptDrawerTabs() {
  const useStyles = makeStyles((theme) => ({
    root: {
      // flexGrow: 1,
      borderBottom: `1px solid ${theme.palette.background.paper}`,
    },
    palette: {
      secondary: {
        main: "#47BB76",
      },
    },
    AppBar: {
      boxShadow: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 10,
    },

    tab: {
      fontSize: 13,
      fontWeight: 500,
      minWidth: "30px",
      textTransform: "capitalize",
      padding: "6px 10px",
      color: "#76A2D0",
      "&.Mui-selected": {
        backgroundColor: "#FEFEFE",
        color: "#15314E",
      },
    },
    tabs: {
      minWidth: "max-content",
      minHeight: "25px",
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { mainDrawer } = useSelector((state) => state.esgGptReducer);

  const { location } = history;

  const tabValue = location.pathname === "/esg_gpt/chat" ? 1 : 0;

  const [value, setValue] = React.useState(tabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRoute = (newPath) => {
    let path = newPath;
    history.push(path);
  };

  const handleAddAction = (type) => {
    if (type === "generic") {
      dispatch(setSelectedChat({}));
    } else {
      history.push("/esg_gpt/add_company");
    }
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <AppBar position="static" color="transparent" className={classes.AppBar}>
        <Tabs className={classes.tabs} value={value} onChange={handleChange}>
          <Tab
            className={classes.tab}
            onClick={() => handleChangeRoute("/esg_gpt/company_profiles")}
            label={<span className={classes.tabLabel}>Companies</span>}
            data-tut="companies-tab"
          />
          <Tab
            className={classes.tab}
            onClick={() => handleChangeRoute("/esg_gpt/chat")}
            label={<span className={classes.tabLabel}>Chats</span>}
            data-tut="chat-tab"
          />
        </Tabs>

        {!mainDrawer && (
          <Tooltip
            title={tabValue === 1 ? "New Chat" : "Add New Company"}
            placement="bottom"
          >
            {tabValue === 1 ? (
              <IconButton onClick={() => handleAddAction("generic")}>
                <img src={EditGeneric} alt="addChat" />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleAddAction("company")}>
                <FiPlus color={"#3374B9"} />
              </IconButton>
            )}
          </Tooltip>
        )}
      </AppBar>
    </MuiThemeProvider>
  );
}
