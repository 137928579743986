import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_CHAT_QUESTION,
  SET_CHAT_ANSWER,
  SET_CHATS_CONVERSATION,
  SET_CHAT_GROUP,
  SET_SELECTED_CHAT,
  SET_SEARCHED_ORGANIZATION,
  SET_SEARCHED_TEXT,
  SET_DISCLAIMER,
  SET_HELP_CENTER_DRAWER,
  SET_MAIN_DRAWER,
  SET_FOLLOWUP_QUES,
  SET_ADD_CHAT,

  // appbar drawres
  SET_SHOW_SETTINGS,
  SET_SHOW_NOTIFICATIONS,

  // payments
  SET_INVOICE_DETAILS,
  SET_NOTIFICATIONS,
  SET_UA_INVOICE,
  SET_UA_ISPAID_STATUS,
} from "../../../constants/esgGptConstant";

const filterDummyData = [
  {
    type: "View",
    id: 1,
    selectOption: 1,
    options: [
      { name: "All", id: 1 },
      { name: "Repository", id: 2 },
      { name: "Favorites", id: 3 },
    ],
  },
  {
    type: "Sort",
    id: 2,
    selectOption: 1,
    options: [
      { name: "High to Low ESG score", id: 1 },
      { name: "Low to High ESG score", id: 2 },
      { name: "Alphabatical", id: 3 },
      { name: "High to Low Revenue", id: 4 },
      { name: "Low to High Revenue", id: 5 },
    ],
  },
  {
    type: "Geography",
    id: 3,
    selectOption: ["IN"],
    options: [
      { name: "All", id: "ALL" },
      { name: "India", id: "IN" },
      { name: "United Kingdom", id: "GB" },
      { name: "United States", id: "US" },
      { name: "Netherlands", id: "NL" },
      { name: "United Arab Emirates", id: "AE" },
    ],
  },
];

const initial_state = {
  acknowledged: false,
  organizations: [],
  searchedOrganizations: { filtered: "", org: [], text: null },
  newOrganization: {},
  newLogo: "",
  chatQuestion: "",
  chatAnswer: "",
  chats: [],
  chatsList: [],
  selectedChat: {},
  isDisclaimer: false,

  mainDrawer: true,
  followUpQues: "",

  // appbar drawers
  helpCenter: false,
  showSettings: false,
  showNotifications: false,

  notifications: [],

  filterData: filterDummyData,

  // payments states
  invoiceDetails: [],

  // ua payments
  uaInvoiceData: null,
};

export default function esgGptReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_DISCLAIMER:
      return (state = { ...state, isDisclaimer: action.payload });
    case SET_ACKNOWLEDGED:
      return (state = { ...state, acknowledged: action.payload });
    case SET_ORGANIZATION:
      return (state = { ...state, organizations: action.payload });
    case SET_SEARCHED_ORGANIZATION:
      return (state = {
        ...state,
        searchedOrganizations: {
          text: state.searchedOrganizations.text,
          filtered: action.payload,
        },
      });
    case SET_SEARCHED_TEXT:
      return (state = {
        ...state,
        searchedOrganizations: {
          ...state.searchedOrganizations,
          text: action.payload,
        },
      });
    case SET_NEW_ORGANIZATION:
      return (state = { ...state, newOrganization: action.payload });
    case SET_NEW_ORGANIZATION_LOGO:
      return (state = { ...state, newLogo: action.payload });
    case SET_CHAT_QUESTION:
      return (state = { ...state, chatQuestion: action.payload });
    case SET_CHAT_ANSWER:
      return (state = { ...state, chatAnswer: action.payload });
    case SET_CHATS_CONVERSATION:
      return (state = { ...state, chats: action.payload });
    case SET_HELP_CENTER_DRAWER:
      return (state = { ...state, helpCenter: !state?.helpCenter });

    case "SET_FILTER":
      return (state = { ...state, filterData: action.payload });
    case SET_CHAT_GROUP:
      // console.log("state", state.chatsList, action.payload);
      let selectedChatdata = () => {
        if (action.payload.length > 0) {
          return action.payload[0];
        } else {
          return {};
        }
      };
      return (state = {
        ...state,
        chatsList: action.payload,
        selectedChat: selectedChatdata(),
      });
    case SET_SELECTED_CHAT:
      return (state = {
        ...state,
        selectedChat: action.payload,
      });

    case SET_MAIN_DRAWER:
      return (state = { ...state, mainDrawer: action.payload });

    case SET_ADD_CHAT:
      const chatToUpdate = state.chats.find(
        (chat) => chat._id === action.payload._id
      );
      if (chatToUpdate) {
        const updatedChat = { ...chatToUpdate, ...action.payload };
        const updatedChats = state.chats.map((chat) =>
          chat._id === action.payload._id ? updatedChat : chat
        );
        return { ...state, chats: updatedChats };
      }
      // If chat with given _id not found, simply return the current state
      return state;

    case SET_FOLLOWUP_QUES:
      return (state = { ...state, followUpQues: action.payload });

    // handling appbar drawers
    case SET_SHOW_SETTINGS:
      return (state = { ...state, showSettings: action.payload });

    case SET_SHOW_NOTIFICATIONS:
      return (state = { ...state, showNotifications: action.payload });

    case SET_NOTIFICATIONS:
      return (state = { ...state, notifications: action.payload });

    case SET_INVOICE_DETAILS:
      return (state = { ...state, invoiceDetails: action.payload });

    case SET_UA_INVOICE:
      return (state = { ...state, uaInvoiceData: action.payload });

    case SET_UA_ISPAID_STATUS:
      const updatedUaInvoiceData = { ...state.uaInvoiceData };

      if ("is_paid" in updatedUaInvoiceData) {
        updatedUaInvoiceData.is_paid = action.payload;
      }
      return {
        ...state,
        uaInvoiceData: updatedUaInvoiceData,
      };
    default:
      return state;
  }
}
