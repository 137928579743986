import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

// icons
import { IoClose } from "react-icons/io5";

const DrawerHeader = ({ onClose, title }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      bgcolor={"#EBF1F8"}
      padding={2}
    >
      <Typography
        style={{
          color: "#15314E",
          fontWeight: 600,
          fontSize: "22px",
          lineHeight: "28px",
        }}
      >
        {title}
      </Typography>
      <IconButton onClick={onClose}>
        <IoClose color="#3374B9" />
      </IconButton>
    </Box>
  );
};
export default DrawerHeader;
