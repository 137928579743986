import React from "react";
import { Bar, defaults } from "react-chartjs-2";

function EnergyCharts({
  isPeer = false,
  kpiChartdata,
  label = "Current Financial Year",
  chartColor = "rgb(0, 0, 100, 0.7)",
  is_premium,
  x_title,
  y_title
}) {
  defaults.font.family = 'Poppins';
  const labels = kpiChartdata && [
    ...Object.entries(kpiChartdata).map(([key, val], i) =>
      key.trim().split(" ")
    ),
  ];
  const data = {
    labels,

    datasets: [
      {
        label: label,
        data: kpiChartdata && [
          ...Object.entries(kpiChartdata).map(([key, val]) => val),
        ],
        backgroundColor: chartColor,
        borderWidth: 1,
        barThickness: 50,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: label,
      },
      legend: {
        display: false,
      },
     
    },
    scales: {
      x: {
        title: {
          display: true,
          text: x_title,
        },
      },
      y: {
        title: {
          display: true,
          text: y_title,
        },
        ticks: {
        callback: function(value, index, values) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Convert to "5k" format
          }
          return value;
        }
      }
      },
    },
    font: {
      family: 'Poppins',
    },

    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <div style={{ height: "100%", width: "95%", position: "relative" }}>
      {kpiChartdata && (
        <>
          <Bar style={{ height: "100%", filter:is_premium ? "blur(0px)" : "blur(5px)" }} options={options} data={data} />

          {/* {is_premium === false && (
            <div
              style={{
                top: 30,
                left: 80,
                backdropFilter: "blur(3px)",
                position: "absolute",
                width: "90%",
                height: "200px",
                marginLeft: "auto",
              }}
            ></div>
          )} */}
        </>
      )}
    </div>
  );
}
// function EnergyCharts({ isPeer = false, kpiChartdata }) {
//   const labels = ["Current Financial Year"];
//   const data = {
//     labels,
//     datasets: [
//     //   {
//     //     label: "Quarter 1",
//     //     data: [kpiChartdata[1]],
//     //     backgroundColor: "rgb(0, 0, 100, 0.9)",
//     //     borderWidth: 1,
//     //     barThickness: 50,
//     //     borderRadius: 5,
//     //   },
//       {
//         label: "Current Financial Year",
//         data: [kpiChartdata[1]],
//         backgroundColor: "rgb(0, 0, 100, 0.7)",
//         borderWidth: 1,
//         barThickness: 50,
//         borderRadius: 5,
//       },
//     //   {
//     //     label: "Quarter 3",
//     //     data: [kpiChartdata[1]],
//     //     backgroundColor: "rgb(0, 0, 100, 0.5)",
//     //     borderWidth: 1,
//     //     barThickness: 50,
//     //     borderRadius: 5,
//     //   },
//     //   {
//     //     label: "Quarter 4",
//     //     data: [kpiChartdata[1]],
//     //     backgroundColor: "rgb(0, 0, 100, 0.3)",
//     //     borderWidth: 1,
//     //     barThickness: 50,
//     //     borderRadius: 5,
//     //   },
//     ],
//   };
//   const options = {
//     plugins: {
//       title: {
//         display: true,
//         text: kpiChartdata[0],
//         //   position:"left"
//       },
//     },

//     responsive: true,
//     maintainAspectRatio: false,
//     // scales: {
//     //   x: {
//     //     // stacked: true,
//     //   },
//     //   y: {
//     //     // stacked: true,
//     //   },
//     // },
//   };
//   return (
//     <div style={{ height: "100%", width: "95%" }}>
//       <Bar style={{ height: "100%" }} options={options} data={data} />
//     </div>
//   );
// }

export default EnergyCharts;
