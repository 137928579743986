import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import blueIcon from "../../../images/IconBlue.png";
import greenIcon from "../../../images/IconGreen.png";
import purpuleIcon from "../../../images/IconPurpule.png";
import yellowIcon from "../../../images/IconYellow.png";
import orangeIcon from "../../../images/IconOrange.png";

ChartJS.register(ArcElement, Tooltip, Legend);

const greenColor = "rgba(0, 135, 108, 1)";
const purpuleColor = "rgba(131, 41, 163, 1)";
const blueColor = "rgba(41, 100, 163, 1)";
const orangeColor = "rgba(214, 153, 92, 1)";
const yellowColor = "rgba(235, 217, 71, 1)";

const colorArr = [
  greenColor,
  greenColor,
  greenColor,
  greenColor,
  greenColor,
  purpuleColor,
  purpuleColor,
  purpuleColor,
  purpuleColor,
  purpuleColor,
  purpuleColor,
  blueColor,
  blueColor,
  orangeColor,
  orangeColor,
  orangeColor,
  orangeColor,
  yellowColor,
  yellowColor,
  yellowColor,
  yellowColor,
];

export const dataOuter = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [3, 4, 5, 6, 3, 5, 5, 5, 5, 6, 5, 7, 3, 3, 4, 5, 3, 3, 4, 5],
      backgroundColor: colorArr,
      borderColor: colorArr,
      borderWidth: 1,
      borderRadius: 4,
      borderJoinStyle: "bevel",
      spacing: 30,
    },
  ],
};

export const dataInner = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [18, 18, 17],

      backgroundColor: [
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
        "rgba(10, 10, 10, 0)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 0.1)",
        "rgba(54, 162, 235, 0.1)",
        "rgba(255, 206, 86, 0.1)",
        "rgba(75, 192, 192, 0.1)",
        "rgba(153, 102, 255, 0.1)",
      ],
      borderWidth: 1,
      borderRadius: 40,
    },
  ],
};

const optionsOuter = {
  cutout: "65%",
  plugins: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  },
};

const optionsInner = {
  cutout: "70%",

  plugins: {
    tooltip: {
      enabled: false,
    },
    labels: {
      render: "label",
    },
    legend: {
      display: false,
    },
  },
};

const textCenterTitle = {
  id: "textCenterTitlee",
  beforeDatasetDraw(chart, args, pluginoptiopns) {
    const { ctx, data } = chart;

    ctx.save();
    ctx.font = "bolder 15px sans-serif";
    ctx.fillStyle = "rgba(21, 67, 40, 0.7)";
    ctx.textAlign = "center";
    ctx.marginBottom = "5px";
    ctx.fillText(
      "ESG Score",
      chart.getDatasetMeta(0).data[0].x,
      chart.getDatasetMeta(0).data[0].y
    );
  },
};

const imageURLs = [greenIcon, purpuleIcon, blueIcon, orangeIcon, yellowIcon];
const images = imageURLs.map((v) => {
  var image = new Image();
  image.src = v;
  return image;
});

const dounutLabelImage = {
  id: "dounutLabelImage",
  afterDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
    } = chart;

    chart.data.datasets.forEach((datasets, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { x, y } = datapoint.tooltipPosition();
        ctx.translate(x, y);
        // (right/left,top/bott)
        index === 0 && ctx.drawImage(images[0], -15, -20); //env
        index === 1 && ctx.drawImage(images[1], -18, -30); //soc
        index === 2 && ctx.drawImage(images[2], -40, -30); // gov
        index === 3 && ctx.drawImage(images[3], -30, -20);
        index === 4 && ctx.drawImage(images[4], -40, -20);

        ctx.translate(-x, -y);
        ctx.restore();
      });
    });
  },
};

function ScoreChart(props) {
  const [scores, setScores] = useState({
    scoreObject: {},
  });
  const [value, setValue] = useState(0);
  const [esgScore, setEsgScore] = useState(0);

  useEffect(() => {
    if (props?.scores) {
      setScores(props.scores);
      if (props?.scores?.esgScore !== undefined) {
        setValue(Math.round(props?.scores?.esgScore));
        setEsgScore(props?.scores?.esgScore);
      } else {
        setValue(0);
        setEsgScore(0);
      }
    }

    return () => {
      setScores({ scoreObject: {} });
      setValue(0);
      setEsgScore(0);
    };
  }, [props.scores, props?.scores?.esgScore]);

  const dataOuter = {
    labels:
      scores?.scoreObject && Object.keys(scores?.scoreObject).length
        ? [
            ...Object.keys(scores?.scoreObject?.environment?.data_points),
            ...Object.keys(scores?.scoreObject?.social?.data_points),
            ...Object.keys(scores?.scoreObject?.governance?.data_points),
          ]
        : [],
    datasets: [
      {
        label: "# of Votes",
        data:
          scores?.scoreObject && Object.keys(scores?.scoreObject).length
            ? [
                ...Object.values(scores?.scoreObject?.environment?.data_points),
                ...Object.values(scores?.scoreObject?.social?.data_points),
                ...Object.values(scores?.scoreObject?.governance?.data_points),
              ]
            : [],
        backgroundColor: colorArr,
        borderColor: colorArr,
        borderWidth: 1,
        borderRadius: 4,
        borderJoinStyle: "bevel",
        spacing: 30,
      },
    ],
  };

  const textCenterValue = {
    id: "textCenterValue",
    beforeDatasetDraw(chart) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.fillStyle = "rgba(21, 67, 40, 1)";
      ctx.textAlign = "center";
      ctx.fillText(
        `${esgScore.toFixed(2)} / 100`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y + 35
      );
    },
  };

  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        filter: props.is_premium ? "blur(0px)" : "blur(5px)",
      }}
    >
      <div style={{ height: "21vh", width: "28vw" }}>
        <Doughnut key={esgScore} options={optionsOuter} data={dataOuter} />
      </div>
      <div
        style={{
          height: "40vh",
          width: "16vw",
          marginTop: "-9vh",
        }}
      >
        <Doughnut
          key={esgScore} // Using key to force re-render when esgScore changes
          options={optionsInner}
          data={dataInner}
          plugins={[textCenterTitle, textCenterValue, dounutLabelImage]}
          redraw={value}
        />
      </div>
    </div>
  );
}

export default ScoreChart;
