import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";

// icons
import { IoIosArrowDown } from "react-icons/io";

// -------- stylings -----------
export const textStyle = (type) => {
  let weight = 400;
  if (type === "main") {
    weight = 500;
  }

  return {
    color: "#15314E",
    fontWeight: weight,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

export const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "complete") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 20,
    textTransform: "capitalize",
    padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
  };
};

export const useTableStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  headingText: {
    color: "#15314E",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  tableRowHover: {
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      cursor: "pointer",
    },
  },

  checkBox: {
    color: "#3374B9",
    padding: "0px 5px",
    "&.Mui-checked": {
      color: "#3374B9",
    },
  },
});

// headerButtonType can take options: sort, mark
const DynamicTable = ({ headings, children, headerButtonType = "sort" }) => {
  const classes = useTableStyles();

  return (
    <Table className={classes.table} aria-label="dynamic table">
      <TableHead className={classes.tableHead}>
        <TableRow>
          {headings.map((heading, index) => (
            <TableCell key={index}>
              <Box className={classes.heading}>
                {headerButtonType === "mark" && heading.showMark && (
                  <Checkbox
                    color="primary"
                    className={classes.checkBox}
                    disableRipple
                  />
                )}
                <Typography className={classes.headingText}>
                  {heading.name}
                </Typography>
                {headerButtonType === "sort" && heading.showSort && (
                  <IoIosArrowDown color={"#3374B9"} />
                )}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
};

export default DynamicTable;
