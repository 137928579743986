import React from "react";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import {
  setAcknowledged,
  setNewCompanyDetails,
  setNewCompanyLogo,
  setSelectedChat,
} from "../../../redux/actions/gpt/esgGptAction";

// icons
import EditGeneric from "../../../images/editGeneric.svg";
import { FiPlus } from "react-icons/fi";
import CustomButton from "../../UI/button/button";

export default function EsgGptAddCompanyButton() {
  const history = useHistory();
  const dispath = useDispatch();
  const { location } = history;

  const buttonText =
    location.pathname === "/esg_gpt/chat" ? "New Chat" : "Add New Company";

  const buttonLogo =
    location.pathname === "/esg_gpt/chat" ? (
      <img src={EditGeneric} alt="addChat" />
    ) : (
      <FiPlus />
    );

  const handleChangeRoute = () => {
    let path = "/esg_gpt/add_company";
    location.pathname !== "/esg_gpt/chat" && history.push(path);

    dispath(setAcknowledged(false));
    dispath(setNewCompanyDetails({}));
    dispath(setNewCompanyLogo(""));
    dispath(setSelectedChat({}));
  };
  return (
    <CustomButton
      variant={"outlined"}
      color={"primary"}
      style={{
        marginTop: "8px",
        textTransform: "capitalize",
        backgroundColor: "#FEFEFE",
        border: "1px solid #3374B9",
        borderRadius: "8px",
      }}
      onClick={handleChangeRoute}
      data-tut="add-newCompany"
      startIcon={buttonLogo}
    >
      {buttonText}
    </CustomButton>
  );
}
