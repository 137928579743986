import React from "react";

import { Typography } from "@material-ui/core";

import settingSvg from "../../../images/settingButton.svg";
import thumbUp from "../../../images/thumbUp.svg";
import errorCircle from "../../../images/errorCircle.svg";
import profileOut from "../../../images/profileOut.svg";
import useHoverEffect from "../../../customHooks/getHoveringEffects";
import UserProfileCard from "../userProfileCard/userProfileCard";
import Feedback from "./Feedback";
import Rating from "@material-ui/lab/Rating";
import { useDispatch } from "react-redux";
import { sendFeedBack } from "../../../redux/actions/gpt/esgGptAction";

// icons
import { FiThumbsUp } from "react-icons/fi";
import { FiInfo } from "react-icons/fi";

export default function EsgMOreBUttons(props) {
  const dispatch = useDispatch();
  const { hasIconOnly = false, login, logOut } = props;
  const { selectedItem, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openFeedBackDialog, setOpenFeedBackDialog] = React.useState(false);
  const [rating, setRating] = React.useState(0);

  const handleCloseFeedBackDialog = (value) => {
    setOpenFeedBackDialog((preValue) => !preValue);
    setRating(0);
  };

  const handleMenuUser = (event) => {
    if (event.currentTarget !== anchorElUser) {
      setAnchorElUser(event.currentTarget);
    }
  };
  function handleUserProfileCard() {
    setAnchorElUser(null);
  }

  const handleFeedBack = (token, feedback) => {
    dispatch(sendFeedBack(token, feedback, rating));
  };

  const moreButtonArr = [
    // { src: settingSvg, name: "Settings" },
    { src: thumbUp, name: "Feedback" },
    { src: errorCircle, name: "Terms & Conditions" },
    // { src: profileOut, name: "User Name" },
  ];
  return (
    <>
      {openFeedBackDialog && (
        <Feedback
          isOpen={openFeedBackDialog}
          handleClose={handleCloseFeedBackDialog}
          additional={true}
          title={"Feedback section"}
          contentText={""}
          extraText={
            "Please rate your platform experience thus far. If possible do include details of your experience in the comment box"
          }
          hasRatingStar={true}
          placeHolderText={
            "What do you like or dislike about our application ?"
          }
          handleFeedBackSubmission={handleFeedBack}
        >
          <Rating
            // name="rating-feedback"
            value={rating}
            onChange={(e, newValue) => {
              e.preventDefault();
              e.stopPropagation();
              setRating(newValue);
            }}
          />
        </Feedback>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: hasIconOnly ? "flex-start" : "center",
          alignItems: "center",
          border: "1px solid #EBF1F8",
          // padding: "6px 20px 6px 10px",
          padding: hasIconOnly ? "5px" : "5px 0px",
          cursor: "pointer",
          // backgroundColor: selectedItem === 0 ? "#EBF1F8" : "#FBFBFB",
        }}
        onClick={(e) => {
          handleCloseFeedBackDialog();
          // console.log(e.target.id,e.target.value,e);
          // if (e.target.id === "Terms & Conditions") window.open("/tnc");
        }}
        onMouseEnter={() => handleMouseEnter(0)}
        onMouseLeave={() => handleMouseLeave(0)}
      >
        {/* <UserProfileCard
        anchorEl={anchorElUser}
        onClose={handleUserProfileCard}
        userName={login?.name}
        userEmail={login?.email}
        token={login?.token}
        logOut={logOut}
        userProfile={login?.userProfile}
        loginDetails={login}
      /> */}

        <FiThumbsUp color="#3374B9" size={20} style={{ margin: "0px 10px" }} />

        {/* <img
          src={thumbUp}
          style={{ width: "35px", height: "35px" }}
          alt="pdf icon"
        /> */}
        {
          hasIconOnly && (
            // (data.name === "Terms & Conditions" ? (
            // <a
            //   href="/tnc"
            //   style={{ textDecoration: "none", color:'inherit' }}
            //   target="_blank"
            // >
            <Typography style={{ fontSize: 13, padding: 5 }}>
              Feedback
            </Typography>
          )
          // </a>
          // ) : (
          //   <Typography style={{ fontSize: 13, padding: 5 }}>
          //     {" "}
          //     {data.name}{" "}
          //   </Typography>))
        }
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: hasIconOnly ? "flex-start" : "center",
          alignItems: "center",
          border: "1px solid #EBF1F8",
          padding: hasIconOnly ? "5px" : "5px 0px",
          cursor: "pointer",
          // backgroundColor: selectedItem === 1 ? "#EBF1F8" : "#FBFBFB",
        }}
        onMouseEnter={() => handleMouseEnter(1)}
        onMouseLeave={() => handleMouseLeave(1)}
        onClick={(e) => {
          window.open("/tnc");
        }}
      >
        <FiInfo color="#3374B9" size={20} style={{ margin: "0px 10px" }} />
        {/* <img
          src={errorCircle}
          style={{ width: "35px", height: "35px" }}
          alt="pdf icon"
        /> */}
        {hasIconOnly && (
          <Typography style={{ fontSize: 13, padding: 5 }}>
            Terms & Conditions
          </Typography>
        )}
      </div>
    </>
  );
  // return moreButtonArr.map((data, index) => (
  //   <div
  //     style={{
  //       width: "100%",
  //       display: "flex",
  //       justifyContent: hasIconOnly ? "flex-start" : "center",
  //       alignItems: "center",
  //       border: "1px solid #EBF1F8",
  //       // padding: "6px 20px 6px 10px",
  //       padding: hasIconOnly ? "5px" : "5px 0px",
  //       cursor: "pointer",
  //       backgroundColor: selectedItem === index ? "#EBF1F8" : "#FBFBFB",
  //     }}
  //     onClick={(e) => {
  //       if (index === 0) handleCloseFeedBackDialog();
  //       // console.log(e.target.id,e.target.value,e);
  //       // if (e.target.id === "Terms & Conditions") window.open("/tnc");
  //     }}
  //     onMouseEnter={() => handleMouseEnter(index)}
  //     onMouseLeave={() => handleMouseLeave(index)}
  //   >
  //     {/* <UserProfileCard
  //       anchorEl={anchorElUser}
  //       onClose={handleUserProfileCard}
  //       userName={login?.name}
  //       userEmail={login?.email}
  //       token={login?.token}
  //       logOut={logOut}
  //       userProfile={login?.userProfile}
  //       loginDetails={login}
  //     /> */}

  //     <img
  //       src={data.src}
  //       style={{ width: "35px", height: "35px" }}
  //       alt="pdf icon"
  //     />
  //     {
  //       hasIconOnly && (
  //         // (data.name === "Terms & Conditions" ? (
  //         // <a
  //         //   href="/tnc"
  //         //   style={{ textDecoration: "none", color:'inherit' }}
  //         //   target="_blank"
  //         // >
  //         <Typography style={{ fontSize: 13, padding: 5 }}>
  //           {data.name}
  //         </Typography>
  //       )
  //       // </a>
  //       // ) : (
  //       //   <Typography style={{ fontSize: 13, padding: 5 }}>
  //       //     {" "}
  //       //     {data.name}{" "}
  //       //   </Typography>))
  //     }
  //     {openFeedBackDialog && (
  //       <Feedback
  //         isOpen={openFeedBackDialog}
  //         handleClose={handleCloseFeedBackDialog}
  //         additional={true}
  //         title={"Feedback section"}
  //         contentText={""}
  //         extraText={
  //           "Please rate your platform experience thus far. If possible do include details of your experience in the comment box"
  //         }
  //         hasRatingStar={true}
  //         placeHolderText={
  //           "What do you like or dislike about our application ?"
  //         }
  //         handleFeedBackSubmission={handleFeedBack}
  //       >
  //         <Rating
  //           // name="rating-feedback"
  //           value={rating}
  //           onChange={(e, newValue) => {
  //             e.preventDefault();
  //             e.stopPropagation();
  //             setRating(newValue);
  //           }}
  //         />
  //       </Feedback>
  //     )}
  //   </div>
  // ));
}
