import { Card, CardActions, CardContent, CardHeader } from "@material-ui/core";

import CustomButton from "../../../UI/button/button";

const CardStructure = ({
  header,
  children,
  actionLabel,
  handleAction,
  disableButton,
}) => {
  return (
    <Card variant="outlined" style={{ minWidth: "750px" }}>
      {/* Header */}
      <CardHeader
        title={header}
        titleTypographyProps={{
          style: {
            color: "#15314E",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "26px",
          },
        }}
        style={{
          textAlign: "center",
          marginBottom: "10px",
          backgroundColor: "#F6F6F6",
          borderBottom: "1px solid #C0D4E9",
        }}
      />

      {/* Main content */}
      <CardContent>{children}</CardContent>

      {/* Action button */}
      {actionLabel && (
        <CardActions style={{ justifyContent: "center", marginTop: "10px" }}>
          <CustomButton
            variant="contained"
            color="primary"
            style={{ padding: ".5em 6em", textTransform: "capitalize" }}
            onClick={handleAction}
            disabled={disableButton}
          >
            {actionLabel}
          </CustomButton>
        </CardActions>
      )}
    </Card>
  );
};
export default CardStructure;
