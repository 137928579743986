import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import left from "../../../images/left.png";
import right from "../../../images/right.png";
import sideleft from "../../../images/sidebarLeft.png";
import sideright from "../../../images/sidebarRight.png";
import cert from "../../../images/cert.png";
import build from "../../../images/build.png";
import logoWhite from "../../../images/ImpactGrows White Corrected.svg";
import user from "../../../images/Avatar.svg";
import tick from "../../../images/tick.png";
import AI from "../../../images/AI.svg";
// import SignupCard from "../../UI/signupCard/signupCard";
import "./signup.css";

import { Redirect } from "react-router-dom";
import signupStyles from "./signupStyles";
import SignupCard from "./signupCard";
import { useSelector } from "react-redux";

const whiteButton = createTheme({
  palette: {
    primary: { main: "#ffffff" },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#3374B9",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});

export default function SignupPage(props) {
  const classes = signupStyles();
  const { login, getCompanyLogo, onlyLogin } = props;
  // const [signupStepNo, setSignupStepNo] = useState(0);
  // const { signupStepNo } = useSelector((state) => state.login);
  const signupStepNo =
    JSON.parse(localStorage.getItem("signup_process_no")) || 0;
  let topics = [
    // "ESG Insight Mastery",
    "AI-powered sustainability guide",
    "AI-powered sustainability guide",
    "AI-powered sustainability guide",
    "AI-powered sustainability guide",
    "AI-powered sustainability guide",

    // "Effortless Navigation Hub",
    // "Effortless Navigation Hub",
    // "Effortless Navigation Hub",
    // "Effortless Navigation Hub",
  ];
  const [sideMessage, setSideMessage] = useState("");
  const [shrink, setShrink] = useState(
    login?.isLoggedIn || onlyLogin ? true : false
  );
  const [redirect] = useState(false);
  useEffect(() => {
    setShrink(login?.isLoggedIn || onlyLogin ? true : false);
  }, [login?.isLoggedIn]);

  useEffect(() => {
    setSideMessage(topics[signupStepNo]);
  }, [signupStepNo]);

  if (redirect) {
    return <Redirect to="/drawer"></Redirect>;
  }
  const subCard = (details) => {
    return (
      <div style={{ display: "flex", padding: "10px 0px" }}>
        <img alt="" src={tick} width="20px" height="20px" />
        <Typography className={classes.sideBarSubTitle}>
          {details || ""}
        </Typography>
      </div>
    );
  };
  return (
    <div className={classes.mainDiv}>
      {/* <Hidden xsDown> */}
      <div className={classes.mainDiv1}>
        <img src={logoWhite} alt="logo" />
      </div>
      <Grid container lassName={classes.mainGrid}>
        <Grid
          container
          item
          className={
            shrink
              ? `blueDivShrinked ${shrink ? "blueDivAnimation" : ""}`
              : `blueDiv ${shrink ? "blueDivAnimation" : ""}`
          }
          style={{ zIndex: "2" }}
        >
          {!shrink && (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid container item xs={4} justifyContent="center">
                <Grid item xs={2}>
                  <img src={user} alt="" className="avatar" />
                </Grid>
                <Grid item xs={12} className={classes.grid}>
                  <Typography style={{ color: "white" }} variant="h6">
                    Hi, <br />
                    This is Kevin
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.grid1}>
                  <Typography className={classes.typo} variant="body1">
                    Ill be handling your onboarding process & be available for
                    any questions regarding our product
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  xs={6}
                  className={classes.grid2}
                >
                  <ThemeProvider theme={whiteButton}>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                          setShrink(true);
                        }}
                        className={classes.Button}
                      >
                        Lets Get Started
                      </Button>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Grid>
              <img src={left} alt="left" className={classes.img1} />
              <img src={right} alt="right" className={classes.img2} />
            </Grid>
          )}
          {shrink && (
            <Grid container>
              <Grid
                sx={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                item
                container
                justifyContent="center"
                className={classes.grid3}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  paddingRight="20px"
                >
                  <img src={sideright} alt="left" className={classes.img3} />
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  paddingBottom="15px"
                  // paddingBottom="190px"
                  // paddingTop="16px"
                  paddingLeft="15px"
                  paddingRight="12px"
                  // maxWidth="270px"
                >
                  <img
                    src={AI}
                    alt="user"
                    style={{ width: "80%", height: "70%" }}
                  />
                  {/* {sideMessage === "ESG Insight Mastery" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          ESG Insight Mastery
                        </Typography>
                        {subCard("Comprehensive Company Profiles")}
                        {subCard("Comprehensive Company Profiles")}
                        {subCard("Comprehensive Company Profiles")}
                      </>
                    )} */}
                  {sideMessage === "AI-powered sustainability guide" && (
                    <>
                      <Typography className={classes.sideBarTitle}>
                        AI-powered sustainability guide
                      </Typography>
                      {subCard("500+ ESG Company profiles")}
                      {subCard("ESG Enabled Knowledge Base")}
                      {subCard("Peer & Industry ESG Performance Benchmarking")}
                    </>
                  )}
                  {sideMessage === "Personalized Account Management" && (
                    <>
                      <Typography className={classes.sideBarTitle}>
                        Personalized Account Management
                      </Typography>
                      {subCard("Customizable Settings Dashboard")}
                      {subCard("Valuable User Feedback")}
                      {subCard("Transparent Policy Information")}
                    </>
                  )}

                  {sideMessage === "firstStep" && (
                    <>
                      <Typography className={classes.sideBarTitle}>
                        Basic Company details
                      </Typography>
                      <Typography className={classes.sideBarSubTitle}>
                        These details help you fill out the Reportings much more
                        faster
                      </Typography>
                    </>
                  )}
                  {sideMessage === "secondStep" && (
                    <>
                      <Typography className={classes.sideBarTitle}>
                        Materiality assesment
                      </Typography>
                      <Typography className={classes.sideBarSubTitle}>
                        This involves frameworks required to make your reports
                      </Typography>
                    </>
                  )}
                  {sideMessage === "thirdStep" && (
                    <>
                      <Typography className={classes.sideBarTitle}>
                        Alright, Lets get started
                      </Typography>
                      <Typography className={classes.sideBarSubTitle}>
                        This involves frameworks required to make your reports
                      </Typography>
                    </>
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  // marginTop='auto'
                  paddingLeft="16px"
                >
                  <img src={sideleft} alt="right" className={classes.img3} />
                </Box>
              </Grid>

              <Grid
                sx={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                item
                style={{
                  marginLeft: "80px",
                  alignSelf: "center",
                }}
                justifyContent="center"
              >
                <Slide
                  direction="right"
                  in={props.login.sideImage === "build"}
                  timeout={500}
                  mountOnEnter
                  unmountOnExit
                >
                  <img src={build} alt="build" />
                </Slide>
                <Slide
                  direction="right"
                  in={props.login.sideImage === "cert"}
                  timeout={500}
                  mountOnEnter
                  unmountOnExit
                >
                  <img src={cert} alt="build" />
                </Slide>
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12} className={classes.grid4}>
                <div className={classes.grid4}>
                  <ul className={classes.ul}>
                    <li className="footerList">
                      <a className={classes.link} href="/faq" target="_blank">
                        FAQ
                      </a>
                    </li>
                    <li className="footerList">
                      <a className={classes.link} href="/tnc" target="_blank">
                        Terms & Conditions{" "}
                      </a>
                    </li>
                    <li className="footerList">
                      <a
                        className={classes.link}
                        href="/privacy_policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item container justifyContent="center" alignItems="center" xs={9}>
          <Grid item xs={7} sm={7} md={7}>
            {shrink && (
              <SignupCard {...props} signupCard={shrink} shrink={shrink} />
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* </Hidden> */}
    </div>
  );
}
