import {
  CHAT_RESET_TIMER,
  CHAT_START_TIMER,
  CHAT_STOP_TIMER,
} from "../../../constants/chatTimerConstants";

// TimerActions.js
export const startChatTimer =
  (question_id, duration, onExpire) => (dispatch) => {
    // Clear any existing timer
    dispatch(stopChatTimer(question_id));

    // Start a new timer
    const timer_id = setTimeout(() => {
      dispatch(onExpire(question_id));
    }, duration);

    console.log("Chat Timer Started for question_id ----> ", question_id);

    dispatch({
      type: CHAT_START_TIMER,
      question_id,
      timer_id,
    });
  };

export const resetChatTimer =
  (question_id, duration, onExpire) => (dispatch) => {
    // Clear the existing timer
    dispatch(stopChatTimer(question_id));

    // Start a new timer with the same duration
    const timer_id = setTimeout(() => {
      dispatch(onExpire(question_id));
    }, duration);

    console.log("Chat Timer Reset for question_id ----> ", question_id);

    dispatch({
      type: CHAT_RESET_TIMER,
      question_id,
      timer_id,
    });
  };

export const stopChatTimer = (question_id) => (dispatch, getState) => {
  const { timers } = getState().chatTimerReducer;

  console.log(
    "Prev/New Chat Timer Stopped for question_id ----> ",
    question_id
  );

  // If there's an existing timer, clear it
  if (timers[question_id]) {
    clearTimeout(timers[question_id]);
  }

  dispatch({
    type: CHAT_STOP_TIMER,
    question_id,
  });
};
