import { Avatar, Box, Typography } from "@material-ui/core";

import CustomTextfield from "../../../../../../UI/textfield/textfield";

// icons
import { LuPen } from "react-icons/lu";
import { LuPenLine } from "react-icons/lu";

import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../../UI/button/button";
import { useState } from "react";
import { updateUserDetails } from "../../../../../../../redux/actions/gpt/settingsAction";
import IOSSwitch from "../../../../../../UI/switch/IOSswitch";

const ProfileTab = () => {
  // from redux
  const dispatch = useDispatch();
  const { token, name, email, notifications, profile_img } = useSelector(
    (state) => state.login
  );

  // states
  const [avatarUrl, setAvatarUrl] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  // State for toggle buttons
  const [toggleSwitch, setToggleSwitch] = useState(notifications || true);
  const [profileName, setProfileName] = useState(name);
  const [hasChanged, setHasChanged] = useState(false);

  // ----------- inner functions -----------
  // Function to handle avatar selection
  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatarFile(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setAvatarUrl(imageUrl);
      setHasChanged(true);
    }
  };

  const handleNameChange = (e) => {
    setProfileName(e.target.value);
    setHasChanged(true);
  };

  // Function to handle toggle changes
  const handleToggleChange = () => {
    setToggleSwitch(!toggleSwitch);
    setHasChanged(true);
  };

  const handleResetClick = () => {
    setAvatarUrl("");
    setToggleSwitch(notifications);
    setProfileName(name);
    setHasChanged(false);
  };

  const handleSubmitClick = () => {
    if (hasChanged) {
      dispatch(
        updateUserDetails(token, {
          profile_img: avatarFile,
          name: profileName,
        })
      );
    }
    setHasChanged(false);
  };
  // ---------------------------------------

  return (
    <Box width={"100%"} height={"100%"} padding={1} position={"relative"}>
      <Typography
        style={{
          borderBottom: "1px solid #EBF1F8",
          paddingBottom: 20,
          ...mainText(),
        }}
      >
        Profile
      </Typography>

      {/* main box */}
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        bgcolor={"#F9F9F9"}
        padding={1}
        style={{ gap: 10, borderRadius: 8 }}
      >
        {/* Avatar Box */}
        <Box
          padding={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ borderBottom: "1px solid #EBF1F8" }}
        >
          <Typography style={{ ...mainText() }}>Avatar</Typography>

          {/* avatar circle render */}
          <Box position={"relative"}>
            <Avatar
              src={avatarUrl || profile_img}
              alt="Avatar"
              style={{ width: "5.5em", height: "5.5em" }}
            />
            <label htmlFor="avatar-input">
              <CustomButton
                aria-label="change avatar"
                size={"small"}
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "#FEFEFE",
                  height: "32px",
                  minWidth: "2.5em",
                  padding: 0,
                  boxSizing: "content-box",
                }}
                component="span"
              >
                <LuPen color={"#3374B9"} size={15} />
              </CustomButton>
            </label>
            {/* Hidden input for file selection */}
            <input
              id="avatar-input"
              type="file"
              accept=".ico, .png, .jpeg, .jpg, .webp"
              style={{ display: "none" }}
              onChange={handleAvatarChange}
            />
          </Box>
        </Box>

        {/* Notifications Box */}
        <Box
          padding={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ borderBottom: "1px solid #EBF1F8" }}
        >
          <Box>
            <Typography style={mainText()}>Notifications</Typography>
            {/* <Typography
              style={{ fontWeight: 400, color: "#676767", fontSize: "12px" }}
            >
              Get notified any changes or requests from the application about
              new companies, library extensions & much more.
            </Typography> */}
          </Box>
          {/* Toggle button */}
          <IOSSwitch checked={toggleSwitch} onChange={handleToggleChange} />
        </Box>

        {/* Username Box */}
        <Box
          padding={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ borderBottom: "1px solid #EBF1F8" }}
        >
          <Typography style={{ ...mainText() }}>User Name</Typography>

          {/* Change username */}
          <CustomTextfield
            variant="standard"
            size={"small"}
            value={profileName}
            style={{ width: "12em" }}
            onChange={handleNameChange}
            InputProps={{
              endAdornment: <LuPenLine color={"#3374B9"} size={20} />,
              disableUnderline: true,
            }}
          />
        </Box>

        {/* Email Box */}
        <Box
          padding={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography style={{ ...mainText() }}>Email ID</Typography>

          {/* Change email */}
          <CustomTextfield
            variant="standard"
            size={"small"}
            defaultValue={email}
            style={{ pointerEvents: "none" }}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
      </Box>

      <Box style={{ position: "absolute", bottom: 2, right: 5 }}>
        {hasChanged && (
          <CustomButton
            variant={"text"}
            color={"primary"}
            style={{ textTransform: "capitalize", marginRight: 20 }}
            onClick={handleResetClick}
          >
            Reset To Default
          </CustomButton>
        )}
        <CustomButton
          variant={"contained"}
          color={"primary"}
          style={{ textTransform: "capitalize", width: "15em" }}
          onClick={handleSubmitClick}
          disabled={!hasChanged}
        >
          Save Details
        </CustomButton>
      </Box>
    </Box>
  );
};
export default ProfileTab;

// --------- stylings ---------
const mainText = () => {
  return {
    color: "#15314E",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  };
};
