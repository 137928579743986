import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "../../../../../images/search.svg";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: "29em",
    backgroundColor: "#FEFEFE",
    borderRadius: "8px",
  },
}));

const DrawerHeader = ({ onClose }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        backgroundColor: "#EBF1F8",
        width: "100%",
        borderBottom: "1px solid #ddd",
        padding: "20px",
        position: "relative",
        marginBottom: "2.2em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Typography
          style={{
            color: "#15314E",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "26px",
          }}
        >
          Help Center
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon style={{ color: "#15314E" }} />
        </IconButton>
      </Box>
      {/* Header label */}
      <Box sx={{ marginBottom: 2  }}>
        <Typography
          style={{
            color: "#5C90C7",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "22px",
          }}
        >
          Guiding your journey with concise, expert support—your go-to Help
          Center.
        </Typography>
      </Box>

      {/* Search Field */}
      {/* <Box sx={{ position: "absolute", left: "7%", bottom: "-15%" }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          className={classes.searchField}
          InputProps={{
            endAdornment: (
              <IconButton>
                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  style={{ width: "18px" }}
                />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            style: {
              marginTop: "-2px",
              color: "#A1BFDF",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "22px",
              paddingBottom: "",
            },
          }}
        />
      </Box> */}
    </Box>
  );
};
export default DrawerHeader;
