import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import { Box, Tooltip } from "@material-ui/core";

// icons
import { TbMinusVertical } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// from redux
import { useSelector } from "react-redux";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    justifyContent: "flex-start",
    border: "1px solid #EBF1F8",
    backgroundColor: "#EBF1F8",
    height: "100vh",
    flexDirection: "column",
    padding: "0px 10px",
  },
  drawerContentWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 66px)",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  iconButton: {
    cursor: "pointer",
    marginLeft: ({ openDrawer }) => (openDrawer ? 0 : -280),
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: "relative",
    overflow: "hidden",
    width: 20,
    height: 40,
    "& svg": {
      position: "absolute",
      top: 0,
      left: 0,
      transition: "transform 0.3s ease",
      // transitionDelay: "0.3s",
    },
    "& .arrow": {
      transform: "scale(0)",
    },
    "& .minus": {
      transform: "scale(1)",
    },
    "&:hover .arrow": {
      transform: "scale(1)",
    },
    "&:hover .minus": {
      transform: "scale(0)",
    },
  },
}));

export default function EsgGptMainLeft({
  drawerTabs,
  drawerSearchBar,
  drawerCompanyList,
  drawerMoreButton,
  drawerAddCompanyButton,
  openDrawer,
  toggleDrawer,
  children,
  ...props
}) {
  const classes = useStyles({ openDrawer });

  // from redux
  const { mainDrawer } = useSelector((state) => state.esgGptReducer);

  return (
    <Box display="flex" alignItems="center">
      {/* main drawer */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={openDrawer}
      >
        <Toolbar />
        <div data-tut="esg-navigator" className={classes.drawerContentWrapper}>
          {drawerAddCompanyButton}
          {drawerSearchBar}
          {drawerCompanyList && (
            <div
              style={{ flex: 1, overflowY: "scroll" }}
              data-tut="company-listing"
            >
              {drawerCompanyList}
            </div>
          )}
          {drawerMoreButton && (
            <div style={{ marginTop: "auto" }} data-tut="insight-guideline">
              {drawerMoreButton}
            </div>
          )}
          {children && <div style={{ marginTop: "auto" }}>{children}</div>}
        </div>
      </Drawer>

      {/* drawer open and close */}
      <Tooltip
        title={mainDrawer ? "Close Drawer" : "Open Drawer"}
        placement="right"
      >
        <div className={classes.iconButton} onClick={toggleDrawer}>
          <TbMinusVertical
            className="icon minus"
            size={30}
            fontVariant="bold"
            color="#C0D4E9"
          />
          {mainDrawer ? (
            <IoIosArrowBack className="icon arrow" size={30} color="#3374B9" />
          ) : (
            <IoIosArrowForward
              className="icon arrow"
              size={30}
              color="#3374B9"
            />
          )}
        </div>
      </Tooltip>
    </Box>
  );
}
