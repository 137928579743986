import { Box, Typography } from "@material-ui/core";
import { format } from "date-fns";

import CardStructure from "./components/CardStructure";

// icons
import { FiUser } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import ESGWiseIcon from "../../../images/EsgWiseIconNew.svg";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { uaInitiatePayment } from "../../../redux/actions/gpt/paymentAction";
import { set_snack_bar } from "../../../redux/actions/snackbar/snackbar_action";
import CheckMark from "../esgGpt/CommonComps/CheckMarkCards";

const ProceedToPay = () => {
  // from redux
  const dispatch = useDispatch();
  const { uaInvoiceData } = useSelector((state) => state.esgGptReducer);
  const userDetails = uaInvoiceData?.user_details;

  const date = new Date();

  // Format to get "3 June 2024"
  const currentDate = format(date, "d MMMM yyyy");

  const handleInitiatePayment = () => {
    if (uaInvoiceData?.order_id) {
      dispatch(uaInitiatePayment(uaInvoiceData?.order_id));
    } else {
      dispatch(set_snack_bar(true, "Please refresh your page"));
    }
  };

  return (
    <CardStructure
      header={"Payment Screen"}
      actionLabel={"Proceed to payment Gateway"}
      handleAction={handleInitiatePayment}
    >
      {/* Main content */}
      <Box
        padding={0.5}
        display={"flex"}
        flexDirection={"column"}
        sx={{ gap: 10 }}
      >
        <Typography
          style={{
            color: "#242424",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
          }}
        >
          Thank You For Choosing Premium Plan
        </Typography>

        {/* user profile box */}
        <Box style={{ ...boxStyles() }}>
          <Box>
            {/* name */}
            <Box display={"flex"} sx={{ gap: 10 }} mb={2}>
              <FiUser color="#3374B9" size={20} />
              <Typography style={{ ...lightText() }}>
                {userDetails.name}
              </Typography>
            </Box>

            {/* email */}
            <Box display={"flex"} sx={{ gap: 10 }}>
              <FiMail color="#3374B9" size={20} />
              <Typography style={{ ...mainText() }}>
                {userDetails.email}
              </Typography>
            </Box>
          </Box>

          <Typography style={{ ...lightText() }}>{currentDate}</Typography>
        </Box>

        {/* support email box */}
        <Box style={{ ...boxStyles() }}>
          <Box display={"flex"} sx={{ gap: 20 }}>
            {/* logo */}
            <img
              src={ESGWiseIcon}
              alt="ESGWiseIcon"
              style={{ width: 50, height: 50 }}
            />

            {/* support email */}
            <Box display={"flex"} flexDirection={"column"} sx={{ gap: 10 }}>
              <Typography style={{ ...lightText() }}>Account</Typography>

              <Typography style={{ ...mainText(true) }}>
                support@billionlives.in
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* plan details */}
        <Box style={{ ...boxStyles() }}>
          <Box>
            {/* plan title */}
            <Typography
              style={{
                color: "#242424",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "24px",
                marginBottom: 10,
              }}
            >
              Premium Plan
            </Typography>

            {/* plan amount */}
            <Box
              style={{
                paddingBottom: 5,
                marginBottom: 5,
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <Typography style={{ ...lightText() }}>For just ₹1000</Typography>
            </Box>

            {/* plan upgrades */}
            {CheckMark("2 Years of Sustainability Data")}
            {CheckMark("500 prompts/month")}
            {CheckMark("500+ Unlocked Company Profiles")}
            {CheckMark("Company Peer analysis")}
            {CheckMark("Company Sentiment & Controversy Profile")}
            {CheckMark("General ESG Chat")}
          </Box>
        </Box>
      </Box>
    </CardStructure>
  );
};

export default ProceedToPay;

// ------------- Stylings -------------
const boxStyles = () => {
  return {
    display: "flex",
    justifyContent: "space-between",
    width: "95%",
    padding: 15,
    backgroundColor: "#F6F6F6",
    borderRadius: "16px",
  };
};

const lightText = () => {
  return {
    color: "#676767",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

const mainText = (isBold) => {
  return {
    color: "#242424",
    fontWeight: isBold ? 600 : 500,
    fontSize: "14px",
    lineHeight: "20px",
  };
};
