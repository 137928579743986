import { useEffect } from "react";
import { Box, Checkbox, TableCell, TableRow } from "@material-ui/core";

// components imports
import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../../UI/dynamicTable/DynamicTable";
import ISOtoDDMMYY from "../../../../utils/dateConverters/ISOtoDDMMYY";
import CustomButton from "../../../UI/button/button";

// icons
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

const InvoiceTable = ({ invoiceDetails }) => {
  const classes = useTableStyles();

  return (
    <Box width={"100%"}>
      {/* Table Header */}
      <DynamicTable headings={InvoiceHeadings} headerButtonType="mark">
        {/* Table Body */}
        {invoiceDetails?.invoice_list?.map((e) => (
          <TableRow key={e?._id} className={classes.tableRowHover}>
            <TableCell style={textStyle("main")}>
              {ISOtoDDMMYY(e?.paid_at || e?.created_at)}
            </TableCell>
            <TableCell style={textStyle()}>{e?.description}</TableCell>
            <TableCell style={textStyle()}>₹ {e?.amount}</TableCell>

            <TableCell>
              <Box style={statusStyle(e?.is_paid ? "complete" : "pending")}>
                <DotIcon style={{ widht: "10px", height: "10px" }} />
                {e?.is_paid ? "complete" : "pending"}
              </Box>
            </TableCell>
            <TableCell style={textStyle()}>
              <CustomButton
                component={"a"}
                href={e?.short_url}
                target="_blank"
                variant={"text"}
                color={"primary"}
                style={{ textTransform: "capitalize" }}
              >
                View Invoice
              </CustomButton>
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default InvoiceTable;

// ---------- Extra Data ----------
const InvoiceHeadings = [
  {
    name: "Date",
  },
  {
    name: "Description",
  },
  {
    name: "Invoice Total",
  },
  {
    name: "Status",
  },
  {
    name: "Action",
  },
];
