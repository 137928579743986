import React, { useState } from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptChatField from "../EsgGptChatField";

import { ModifiedList } from "../esgGptAddNewCompany/EsgGptUpdatedList";
import analytics from "../../../../images/Analytics.png";
import { useSelector } from "react-redux";
import GptTour, {
  TourContent,
  tourStyle,
} from "../../../screen/gptTour/GptTour";
import GenericChatTopCards from "./genericChatComps/GenericChatTopCards";
import { ChatPlayGround } from "../EsgGptPlayground";

const chatSteps = [
  {
    selector: '[data-tut="chat-tab"]',
    content: () => (
      <TourContent
        heading={"Chats Tab"}
        content={
          "Engage in real-time conversations with our intelligent assistant, gaining instant clarity and deeper understanding of ESG intricacies through personalized chats tailored to your queries."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="user-profile"]',
    content: () => (
      <TourContent
        heading={"User Profile"}
        content={
          "Utilize this tab for updating your personal details and logging out as needed."
        }
      />
    ),
    style: tourStyle,
  },
  {
    selector: '[data-tut="token"]',
    content: () => (
      <TourContent
        heading={"Tokens"}
        content={
          "Each token serves as a question, allowing you to inquire about companies' ESG practices or any other information with our chatbot."
        }
        token={true}
      />
    ),
    style: tourStyle,
  },
];

function EsgGptChat(props) {
  const { selectedChat } = useSelector((state) => state.esgGptReducer);
  const [chatInitiate, setChatInitiate] = useState(false);

  // card state
  const [selectedCard, setSelectedCard] = useState(null);

  const handleChat = () => {
    setChatInitiate(true);
  };
  const isChatTourCompleted = JSON.parse(
    localStorage.getItem("chat_tour_completed")
  );

 

  return (
    <>
      {!isChatTourCompleted && <GptTour steps={chatSteps} resource={"chat"} />}
      <EsgGptlayout
        EsgCompanyList={<ModifiedList />}
        login={props.login}
        logOut={props.logOut}
      >
        <div
          style={{
            height: "88%",
            width: "100%",
            overflow: "scroll",
            // marginBottom: "1%",
          }}
          // onScroll={() => handleScroll()}
        >
          {Object.keys(selectedChat).length === 0 && (
            <div
              style={{
                display: "flex",
                height: "70%",
                justifyContent: "center",
              }}
            >
              <GenericChatTopCards
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
              />
              {/* <img src={analytics} alt="analytics" /> */}
            </div>
          )}
          <div onClick={handleChat}>
            <ChatPlayGround />
          </div>
        </div>

        <EsgGptChatField selectedCard={selectedCard} />
      </EsgGptlayout>
    </>
  );
}

export default EsgGptChat;
