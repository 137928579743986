import React, { useEffect, useState } from "react";

import "../../../styles/drawer.css";

import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import CachedIcon from "@material-ui/icons/Cached";

import ListItemText from "@material-ui/core/ListItemText";
import {
  setAcknowledged,
  setNewCompanyDetails,
  setSearcheText,
  setSearchedOrg,
  viewOrganizationEsgGpt,
} from "../../../redux/actions/gpt/esgGptAction";
import { Typography, Box, IconButton } from "@material-ui/core";
import CustomCompaniesRange from "../../UI/CustomRange/CustomCompaniesRange";
import CompanyPlaceholder from "../../../images/CompanyPlaceholder.png";
import { useHistory } from "react-router-dom";
import CustomButton from "../../UI/button/button";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    height: "100%",
    // flex:1

    // overflow: "scroll",
  },
}));
export const ListItemLink = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#C0D4E9",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "##3374B9",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#C0D4E9",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      borderRadius: "8px",
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },

    color: "#6C6C6C",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
    backgroundColor: "#EBF1F8",
  },
  selected: {
    backgroundColor: "#EBF1F8",
    color: "#15314E",
    fontSize: "14px",
    fontWeight: 600,
  },
})(MuiListItem);
export const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#C0D4E9",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#C0D4E9",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },

    color: "#6C6C6C",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#EBF1F8",
    color: "#15314E",
    fontSize: "14px",
    fontWeight: 600,
  },
})(MuiListItem);

export default function EsgGptCompanyList({ handleSelectedId }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const login = useSelector((state) => state.login);

  const {
    organizations,
    totalCompanies,
    searchedOrganizations,
    newOrganization,
    acknowledged,
  } = useSelector((state) => state.esgGptReducer);

  const [selectedID, setSelectedId] = useState(0);

  // for pagination
  const [companiesData, setCompaniesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // page
  const [limit, setLimit] = useState(10); // max data to fetch

  const handleListItemClick = (event, _id, organization) => {
    window.location.pathname !== "/esg_gpt/company_profiles" &&
      history.push("/esg_gpt/company_profiles");
    acknowledged && dispatch(setAcknowledged(false));
    setSelectedId(_id);
    dispatch(setNewCompanyDetails(organization));
    handleSelectedId && handleSelectedId();
  };

  const loadMoreCompanies = () => {
    const nextPage = currentPage + 10;
    dispatch(viewOrganizationEsgGpt(login.token, limit, nextPage));
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    dispatch(viewOrganizationEsgGpt(login.token, limit, currentPage));

    return () => {
      dispatch(setSearchedOrg([]));
      dispatch(setSearcheText(""));
    };
  }, [login.token]);

  useEffect(() => {
    if (Object.keys(organizations).length === 0) {
      setCompaniesData([]);
    } else {
      organizations?.repositories &&
        setSelectedId(organizations?.repositories[0]?._id);
      !newOrganization?._id &&
        organizations?.repositories &&
        dispatch(setNewCompanyDetails(organizations?.repositories[0]));

      if (!searchedOrganizations?.text || searchedOrganizations.text === "") {
        setCompaniesData((prevCompaniesData) => {
          // Merge the existing data with the new data
          return [...prevCompaniesData, ...(organizations?.repositories || [])];
        });
      } else {
        setCompaniesData(organizations?.repositories);
      }
    }
  }, [organizations.repositories, organizations]);

  useEffect(() => {
    if (searchedOrganizations?.text === "") setCompaniesData([]);

    setCurrentPage(0);
  }, [searchedOrganizations?.text]);

  return (
    // <div className={classes.root}>
    <List
      component="nav"
      aria-label="main mailbox folders"
      style={{ height: "100%" }}
    >
      {organizations ? (
        searchedOrganizations?.text && searchedOrganizations?.text !== "" ? (
          companiesData?.map((organizations, i) => {
            return (
              <ListItem
                key={`${organizations?._id}-searched`}
                button
                selected={newOrganization?._id === organizations?._id}
                onClick={(event) =>
                  handleListItemClick(event, organizations?._id, organizations)
                }
                style={{ borderRadius: "8px", margin: "5px" }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        fontSize: 13,
                        fontWeight:
                          newOrganization?._id === organizations?._id
                            ? 500
                            : 400,
                      }}
                    >
                      {organizations?.name}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "90%",
                padding: "8px 12px 8px 0px",
                overflow: "scroll",
                scrollBehavior: "smooth",
              }}
              className="listScroll"
            >
              {(!searchedOrganizations.text ||
                searchedOrganizations.text === "") &&
                companiesData?.map((organizations, i) => {
                  return (
                    <ListItem
                      key={`${organizations?._id}`}
                      button
                      selected={newOrganization?._id === organizations?._id}
                      onClick={(event) =>
                        handleListItemClick(
                          event,
                          organizations?._id,
                          organizations
                        )
                      }
                      style={{ borderRadius: "8px", margin: "5px" }}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        width={"100%"}
                        style={{ gap: 10 }}
                      >
                        <img
                          src={organizations?.logo || CompanyPlaceholder}
                          alt="orgLogo"
                          style={{
                            width: "2.5em",
                            aspectRatio: "1:1",
                            padding: "5px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                          }}
                        />
                        <ListItemText
                          primary={
                            <Typography
                              style={{
                                fontSize: 13,
                                fontWeight:
                                  newOrganization?._id === organizations?._id
                                    ? 500
                                    : 400,
                              }}
                            >
                              {organizations?.name}
                            </Typography>
                          }
                        />

                        <IconButton
                          style={{
                            color: "#3374B9",
                            marginLeft: "auto",
                            backgroundColor:
                              newOrganization?._id === organizations?._id
                                ? "#FBFBFB"
                                : "transparent",
                            borderRadius: "10px",
                            height: "35px",
                            // marginRight: "15px",
                            padding: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          aria-label="more options"
                        >
                          <MoreVertIcon
                            style={{
                              color: "#3374B9",
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </ListItem>
                  );
                })}
              {!searchedOrganizations.text &&
                currentPage <= organizations?.total && (
                  <div
                    style={{
                      height: "10%",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <CustomButton
                      button
                      onClick={loadMoreCompanies}
                      color={"primary"}
                      variant="outlined"
                      style={{
                        // backgroundColor: "#EBF1F8",
                        color: "#15314E",
                        fontSize: "12px",
                        fontWeight: 500,
                        textAlign: "center",
                        backgroundColor: "#FEFEFE",
                        border: "1px solid #3374B9",
                        // height: "40px",
                        borderRadius: "8px",

                        width: "90%",
                        textTransform: "capitalize",
                      }}
                      size="small"
                    >
                      <CachedIcon
                        style={{
                          fontSize: "20px",
                          color: "#3374B9",
                          marginRight: "10px",
                        }}
                      />
                      Load More Companies
                      {/* <ListItemText primary="Load More" /> */}
                    </CustomButton>
                  </div>
                )}
            </div>
          </div>
        )
      ) : (
        <Typography style={{ textAlign: "center", fontSize: 14 }}>
          No Company found.
        </Typography>
      )}
    </List>
    // {/* </div> */}
  );
}
