import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// component imports
import ResetPasswordMain from "../../components/screen/resetPassword/resetPasswordMain";

const ResetPasswordCont = () => {
  const history = useHistory();
  const { location } = history;

  const params = new URLSearchParams(location.search);
  const reset_code = params.get("reset");
  const email_id = params.get("email");

  if (!reset_code) {
    window.location.href = "/";
  }

  useEffect(() => {
    if (reset_code) {
      // fetch user details
      // dispatch(uaResetPassword(reset_code));
    }
  }, [reset_code]);

  return <ResetPasswordMain reset_code={reset_code} email_id={email_id} />;
};
export default ResetPasswordCont;
