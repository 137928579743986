import { Box, Typography } from "@material-ui/core";
import CustomButton from "../../../../../UI/button/button";

const ResourceHeader = ({ icon, title, showView = true }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      borderBottom={"1px solid #A1BFDF"}
      style={{ padding: showView ? "5px 10px" : "15px 10px" }}
    >
      <Box display={"flex"} alignItems={"center"} style={{ gap: 10 }}>
        <img src={icon} alt="Sources" style={{ height: 15, width: 15 }} />
        <Typography
          style={{ fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}
        >
          {title}
        </Typography>
      </Box>

      {showView && (
        <CustomButton
          variant={"text"}
          color={"primary"}
          style={{
            textTransform: "capitalize",
          }}
        >
          View All
        </CustomButton>
      )}
    </Box>
  );
};
export default ResourceHeader;
