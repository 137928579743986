import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Grid } from "@material-ui/core";
import EsgGptCompanyLogo from "./EsgGptCompanyLogo";
import EsgGptFormField from "./EsgGptFormField";
import EsgGptNewCompanyDetails from "./EsgGptNewCompanyDetails";
import CompanyLoading from "../CompanyLoading";

function EsgGptCompanyForm() {
  const { acknowledged } = useSelector((state) => state.esgGptReducer);

  return (
    <Grid container style={{ width: "100%", height: "85%" }}>
      <Grid item md={2} style={{ height: "80%" }}>
        <EsgGptCompanyLogo />
      </Grid>
      <Grid item md={10}>
        {acknowledged ? (
          <div className="scroll" style={{ height: "100vh" }}>
            <EsgGptNewCompanyDetails height="25%" />
            <CompanyLoading margin={15} />
          </div>
        ) : (
          <EsgGptFormField />
        )}
      </Grid>
    </Grid>
  );
}

export default EsgGptCompanyForm;
