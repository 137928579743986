import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, defaults } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Total Energy (MegaJoules)",
      //   position:"left"
    },
  },

  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["Current Financial year", "Previous Financial year"];

export const data = {
  labels,
  datasets: [
    {
      label: "Quarter 1",
      data: [200, 100, 300],
      backgroundColor: "rgb(0, 0, 100, 0.9)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
    {
      label: "Quarter 2",
      data: [200, 100, 300],
      backgroundColor: "rgb(0, 0, 100, 0.7)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
    {
      label: "Quarter 3",
      data: [500, 400, 300],
      backgroundColor: "rgb(0, 0, 100, 0.5)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
    {
      label: "Quarter 4",
      data: [300, 100, 500],
      backgroundColor: "rgb(0, 0, 100, 0.3)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
  ],
};

export default function Charts({ isPeer = false, peer_companies }) {
  defaults.font.family = 'Poppins';
  const options1 = {
    plugins: {
      title: {
        display: false,
        text: "Total Energy (MegaJoules)",
        //   position:"left"
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Company name',
        },
      },
      y: {
        stacked: true,
        min: 0,
        max: 100,
        stepSize: 5,

        title: {
          display: true,
          text: 'ESG - Score ',
        },
      },
    },
  };

  const labels1 = ["Environment Scores", "Social Scores", "Governance Scores"];
  // peer_companies.map()

  const data1 = {
    labels:
      (peer_companies &&
        peer_companies.map(({ company_name }) => company_name)) ||
      [],
    datasets: [
      {
        label: "Environment Scores",
        data:
          (peer_companies &&
            peer_companies.map(({ environment_score }) => environment_score)) ||
          [],
        backgroundColor: "rgb(255, 99, 132)",
        borderWidth: 1,
        barThickness: 70,
        borderRadius: 5,
      },
      {
        label: "Social Scores",
        data:
          (peer_companies &&
            peer_companies.map(({ social_score }) => social_score)) ||
          [],
        backgroundColor: "rgb(255, 159, 64)",
        borderWidth: 1,
        barThickness: 70,
        borderRadius: 5,
      },
      {
        label: "Governance Scores",
        data:
          (peer_companies &&
            peer_companies.map(({ governance_score }) => governance_score)) ||
          [],
        backgroundColor: "rgb(75, 192, 192)",
        borderWidth: 1,
        barThickness: 70,
        borderRadius: 5,
      },
      // {
      //   label: "Company 4",
      //   data: [300, 100, 500, 200],
      //   backgroundColor: "rgb(153, 102, 255)",
      //   borderWidth: 1,
      //   barThickness: 70,
      //   borderRadius: 5,
      // },
    ],
  };

  return (
    <div style={{ height: "100%" }}>
      <Bar
        style={{ height: "100%" }}
        options={isPeer ? options1 : options}
        data={isPeer ? data1 : data}
      />
    </div>
  );
}
export const LineCharts = ({ isPeer = true, year_wise_data }) => {
  const options1 = {
    plugins: {
      title: {
        display: false,
        text: "Total Energy (MegaJoules)",
        //   position:"left"
      },
    },

    responsive: true,
    lineWidth: 100,
    maintainAspectRatio: false,
    // scales: {
    //   x: {
    //     stacked: true,
    //   },
    //   y: {
    //     stacked: true,
    //   },
    // },
  };

  const labels1 = ["Environment Scores", "Social Scores", "Governance Scores"];
  // peer_companies.map()

  let data = year_wise_data?.sort(
    (a, b) => a.year.split("-")[0] - b.year.split("-")[0]
  );
  // console.log("data", data);
  let years = year_wise_data?.map((data) => data.year);
  // ["2020-2021", "2023-2024"];
  let yr =
    years
      ?.map((y) => y.split("-"))
      .flat()
      .sort() || [];
  let def = Math.max(...yr) - Math.min(...yr);
  // console.log("year", def);
  let year = [];
  for (let i = 0; i <= def; i++) {
    year.push(Math.min(...yr) + i);
  }
  let ans = year_wise_data?.map((data) => data.score);
  // console.log("year", year);
  let dta = [];
  let j = 0;
  for (let i = 0; i <= def; i++) {
    if (Number(years[j]?.split("-")[1]) === year[i]) {
      dta.push(ans[j]);
      j++;
    } else {
      dta.push(0);
    }
  }

  const data1 = {
    labels: year || [],
    datasets: [
      {
        label: year_wise_data?.[0]?.company_name,
        data: dta || [],
        backgroundColor: "#3374b9",
        borderWidth: 3,
        // lineThickness: 70,
        // borderRadius: 5,
        tension: 0.1,
        borderColor: "#3374b9",
      },
      // {
      //   label: "Social Scores",
      //   data:
      //     (peer_companies &&
      //       peer_companies.map(({ social_score }) => social_score )) ||
      //     [],
      //   backgroundColor: "rgb(255, 159, 64)",
      //   borderWidth: 1,
      //   barThickness: 70,
      //   borderRadius: 5,
      // },
      // {
      //   label: "Governance Scores",
      //   data:
      //     (peer_companies &&
      //       peer_companies.map(
      //         ({ governance_score }) => governance_score
      //       )) ||
      //     [],
      //   backgroundColor: "rgb(75, 192, 192)",
      //   borderWidth: 1,
      //   barThickness: 70,
      //   borderRadius: 5,
      // },
      // {
      //   label: "Company 4",
      //   data: [300, 100, 500, 200],
      //   backgroundColor: "rgb(153, 102, 255)",
      //   borderWidth: 1,
      //   barThickness: 70,
      //   borderRadius: 5,
      // },
    ],
  };

  return (
    <div style={{ height: "100%" }}>
      <Line
        style={{ height: "100%" }}
        options={isPeer ? options1 : options}
        data={isPeer ? data1 : data}
      />
    </div>
  );
};
