import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import LockIcon from "../../../../../../images/lockIcon.png";
import PlanExpired from "../../../PlanExpired";

export function LockIconDialog() {
  const [planExpired, setPlanExpired] = useState(false);

  const togglePlanExpired = () => {
    setPlanExpired(!planExpired);
  };
  return (
    <>
      {planExpired && (
        <PlanExpired isOpen={planExpired} handleClose={togglePlanExpired} />
      )}

      <img src={LockIcon} alt="lockedScore" style={{cursor:"pointer"}} onClick={() => setPlanExpired(true)} />
    </>
  );
}

const generateScoreCardContent = (score, is_premium) => {
  return (
    <Typography
      align="center"
      style={{
        color: "#3374B9",
        fontWeight: 600,
        fontSize: "45px",
        lineHeight: "67.5px",
      }}
    >
      {typeof score === "number" ? (
        parseFloat(score)?.toFixed(2)
      ) : !is_premium ? (
        <LockIconDialog />
      ) : (
        score
      )}
      <span
        style={{
          color: "#6C6C6C",
          fontWeight: 600,
          fontSize: "22px",
          lineHeight: "28px",
        }}
      >
        /100
      </span>
    </Typography>
  );
};

const ScoreCard = ({ content, is_premium }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width={"100%"}
      height={"8em"}
    >
      <Typography
        style={{
          color: "#0F0F0F",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
        }}
        gutterBottom
      >
        {content?.title}
      </Typography>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        width={"100%"}
      >
        <Box display={"flex"}>
          {generateScoreCardContent(content?.value || 0, is_premium)}
        </Box>
        <Box>
          {content?.image && (
            <img
              src={content?.image}
              alt="Score Card"
              style={{
                width: "80px",
                height: "80px",
                marginTop: -24,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ScoreCard;
