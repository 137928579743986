import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";

// icons
import { FiCompass } from "react-icons/fi";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { IoIosGitCompare } from "react-icons/io";

// component imports
import TopCards from "./TopCards/TopCards";

// redux imports
import { useSelector } from "react-redux";

const GenericChatTopCards = ({ selectedCard, setSelectedCard }) => {
  // from redux
  const { name } = useSelector((state) => state.login);

  const firstName = name.split(" ")[0];

  // ------------------- functions -------------------
  const handleCardSelect = (cardType) => {
    setSelectedCard(cardType);
  };
  // -------------------------------------------------

  // ------------------- useEffects -------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".cards-container")) {
        setSelectedCard(null);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // -------------------------------------------------

  return (
    <Box maxWidth={"70em"}>
      {/* Heading text */}
      <Typography
        style={{
          color: "#245283",
          fontWeight: 500,
          fontSize: "50px",
          lineHeight: "78.5px",
          textTransform: "capitalize",
        }}
      >
        Hello, {firstName}
      </Typography>

      {/* sub text */}
      <Typography
        style={{
          color: "#76A2D0",
          fontWeight: 600,
          fontSize: "40px",
          lineHeight: "64.5px",
        }}
      >
        Let’s embark on your sustainability journey
      </Typography>

      {/* cards */}
      <Box display={"flex"} style={{ gap: 20 }} className="cards-container">
        {cardsData.map((cardData) => (
          <Box key={cardData.type}>
            <TopCards
              cardData={cardData}
              isSelected={selectedCard === cardData.type}
              onSelect={() => handleCardSelect(cardData.type)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default GenericChatTopCards;

export const cardsData = [
  {
    icon: FiCompass,
    heading: "Discover",
    subText:
      "Explore ESG scores, insights, and visualizations for over 100 companies",
    type: "discover",
  },
  {
    icon: FaArrowRightArrowLeft,
    heading: "Engage",
    subText:
      "Interact with real-time ESG data through intuitive chat conversations",
    type: "engage",
  },
  {
    icon: IoIosGitCompare,
    heading: "Compare",
    subText: "Request details for new companies to enrich the ESG Wise library",
    type: "compare",
  },
];
