import { Box, Typography } from "@material-ui/core";

// component imports
import CustomCompaniesRange from "../../../../../UI/CustomRange/CustomCompaniesRange";
import { gptPeerAnalysisText } from "../../../../../../utils/riskUtils/riskUtils";

const PeerSectionOne = ({ is_premium, riskEvaluator }) => {
  // extracting values
  const { final_esg_score, max_esg_score, min_esg_score, peer_companies } = {
    ...riskEvaluator?.peerScores,
  };

  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Box style={{ width: "45%" }}>
        <CustomCompaniesRange
          fullwidth={false}
          peer_companies={peer_companies}
          score={final_esg_score?.score?.toFixed(2)}
          lowerRange={min_esg_score?.score?.toFixed(2)}
          upperRange={max_esg_score?.score?.toFixed(2)}
          is_premium={is_premium}
        />
      </Box>
      <div
        style={{
          width: "45%",
          filter: is_premium ? "blur(0px)" : "blur(5px)",
        }}
      >
        <Typography
          style={{
            paddingLeft: 15,
            fontWeight: 500,
            marginTop: "2px",
            fontSize: "14px",
            color: "#333F48",
          }}
        >
          Based on our market insights and benchmarking tool,
        </Typography>
        <Typography
          style={{
            paddingLeft: 15,
            color: "#333F48",
            fontWeight: 500,
            fontSize: "14px",
            marginTop: "4px",
          }}
        >
          {` Your company is in the
              ${
                final_esg_score?.score <= 25
                  ? "first Quartile"
                  : final_esg_score?.score > 25 && final_esg_score?.score <= 50
                  ? "second quartile"
                  : final_esg_score?.score > 50 && final_esg_score?.score < 75
                  ? "third Quartile"
                  : "fourth quartile"
              }
              of the Peer group range and has significant scope to improve.`}
        </Typography>
        <Typography
          style={{
            paddingLeft: 15,
            fontWeight: 500,
            marginTop: "2px",
            fontSize: "14px",
            color: "#333F48",
          }}
        >
          Our curated sustainability solutions are designed to help you progress
          your company’s sustainability goals to the next level.
        </Typography>

        {gptPeerAnalysisText().map((topic) => {
          return (
            <Typography
              key={topic}
              style={{
                paddingLeft: 15,
                fontSize: "13px",
                color: "#333F48",
                marginTop: "15px",
              }}
            >
              {topic}
            </Typography>
          );
        })}
      </div>
    </Box>
  );
};
export default PeerSectionOne;
