import { useState } from "react";
import { Box, IconButton, InputAdornment, Typography } from "@material-ui/core";

import CardStructure from "./components/CardStructure";
import Textfield from "../../UI/textfield/textfield";

// icons
import { FiMail, FiEye, FiEyeOff } from "react-icons/fi";

// redux imports
import { useDispatch } from "react-redux";
import { resetForgetPassword } from "../../../redux/actions/login/loginAction";

const ResetPassword = ({ reset_code, email_id }) => {
  // from redux
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Determine if the password and confirm password are the same
  const arePasswordsEqual =
    reset_code && password && confirmPassword && password === confirmPassword;

  const handleResetPassword = () => {
    dispatch(resetForgetPassword(password, reset_code));
  };

  return (
    <CardStructure
      header={"Reset Password"}
      actionLabel={"Reset Password"}
      handleAction={handleResetPassword}
      disableButton={!arePasswordsEqual}
    >
      {/* Main content */}
      <Box padding={0.5}>
        <Typography style={{ ...lightText(), marginBottom: 40 }}>
          Please ensure the email and proceed to type your new password.
        </Typography>

        <Box display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
          {/* email id */}
          <Box display={"flex"} flexDirection={"column"} sx={{ gap: 5 }}>
            <label htmlFor="EmailID" style={{ ...lighterText() }}>
              Email Id
            </label>
            <Textfield
              id={"EmailID"}
              variant={"outlined"}
              value={email_id}
              color={"primary"}
              size={"small"}
              style={{ pointerEvents: "none" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size={"small"}>
                      <FiMail color={"#3374B9"} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* new password */}
          <Box display={"flex"} flexDirection={"column"} sx={{ gap: 5 }}>
            <label htmlFor="Password" style={{ ...lighterText() }}>
              New Password
            </label>
            <Textfield
              id={"Password"}
              variant={"outlined"}
              color={arePasswordsEqual ? "secondary" : "primary"}
              type={showPassword ? "text" : "password"} // Toggle type
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size={"small"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size={"small"}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <FiEye color={"#3374B9"} />
                      ) : (
                        <FiEyeOff color={"#3374B9"} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography style={{ ...lighterText(), width: "30em" }}>
              At least 8 character, 1 uppercase, 1 lowercase, 1 number and 1
              symbol.
            </Typography>
          </Box>

          {/* confirm password */}
          <Box display={"flex"} flexDirection={"column"} sx={{ gap: 5 }}>
            <label htmlFor="ConfirmPassword" style={{ ...lighterText() }}>
              Confirm Password
            </label>
            <Textfield
              id={"ConfirmPassword"}
              variant={"outlined"}
              color={arePasswordsEqual ? "secondary" : "primary"}
              type={showCnfPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              size={"small"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size={"small"}
                      onClick={() => setShowCnfPassword(!showCnfPassword)}
                    >
                      {showCnfPassword ? (
                        <FiEye color={"#3374B9"} />
                      ) : (
                        <FiEyeOff color={"#3374B9"} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </CardStructure>
  );
};
export default ResetPassword;

// ------------- Stylings -------------

const lighterText = () => {
  return {
    color: "#9A9A9A",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

const lightText = () => {
  return {
    color: "#676767",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  };
};
