import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const TopCards = ({ cardData, isSelected, onSelect }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      width={"auto"}
      height={"auto"}
      p={2}
      style={{
        backgroundColor: isSelected ? "#3374B9" : "#EBF1F8",
        borderRadius: "16px",
        gap: "24px",
        cursor: "pointer",
        transition: "all 0.3s ease",
      }}
      onClick={onSelect}
    >
      <Box
        width={"4em"}
        height={"4em"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{
          backgroundColor: isSelected ? "#EBF1F8" : "#3374B9",
          borderRadius: 50,
        }}
      >
        <cardData.icon size={25} color={isSelected ? "#3374B9" : "#EBF1F8"} />
      </Box>

      <Typography
        style={{
          color: isSelected ? "#EBF1F8" : "#245283",
          fontWeight: 500,
          fontSize: "26px",
          lineHeight: "24px",
        }}
      >
        {cardData?.heading}
      </Typography>

      <Typography
        style={{
          color: isSelected ? "#A1BFDF" : "#245283",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        {cardData?.subText}
      </Typography>
    </Box>
  );
};
export default TopCards;
