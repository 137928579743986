import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import { initializePayment } from "../../../redux/actions/gpt/paymentAction";

import CustomButton from "../../UI/button/button";
import CheckMark from "./CommonComps/CheckMarkCards";

function PlanExpired({ isOpen, handleClose, onSubmit, sourceName = "" }) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state?.login);

  const handleUpgradePlan = () => {
    dispatch(initializePayment(login?.token));
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <div style={{ width: "760px" }}>
        <DialogTitle
          style={{ backgroundColor: "#EBF1F8", padding: 10, paddingBottom: 30 }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                padding: 5,
                fontSize: 22,
                fontWeight: 600,
                color: "#15314E",
              }}
            >
              Buy Tokens
            </Typography>
            <IconButton onClick={handleClose}>
              <IoClose color="#3374B9" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            border: "1px solid #C0D4E9",
            display: "flex",
            justifyContent: "space-evenly",
            gap: 20,
          }}
        >
          <Box style={contentBox()}>
            <Typography style={contentTitle()}>Free Plan</Typography>
            <Typography style={contentPricing()}>₹ 0</Typography>
            <CustomButton
              variant={"outlined"}
              color={"primary"}
              fullWidth
              size={"large"}
              style={{ textTransform: "capitalize" }}
            >
              Free Plan
            </CustomButton>

            <Box>
              {CheckMark("2 Years of Sustainability Data")}
              {CheckMark("50 prompts/month")}
              {CheckMark("Locked Company Profiles")}
              {CheckMark("General ESG Chat")}
            </Box>
          </Box>
          <hr
            style={{ borderLeft: "1px solid #C0D4E9", margin: "-20px 0px" }}
          />
          <Box style={contentBox()}>
            <Typography style={contentTitle()}>Plus Plan</Typography>
            <Typography style={contentPricing()}>₹ 1000/month</Typography>
            <CustomButton
              variant={"contained"}
              color={"primary"}
              fullWidth
              size={"large"}
              style={{ textTransform: "capitalize" }}
              onClick={handleUpgradePlan}
            >
              Upgrade to plus plan
            </CustomButton>
            <Box>
              {CheckMark("2 Years of Sustainability Data")}
              {CheckMark("500 prompts/month")}
              {CheckMark("500+ Unlocked Company Profiles")}
              {CheckMark("Company Peer analysis")}
              {CheckMark("Company Sentiment & Controversy Profile")}
              {CheckMark("General ESG Chat")}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#6C6C6C",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              padding: 10,
            }}
          >
            Need more capability? Contact our team
          </Typography>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default PlanExpired;

// -------------- Styings --------------

const contentBox = () => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
    width: "100%",
    height: "100%",
    minHeight: "10em",
  };
};

const contentTitle = () => {
  return {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "28px",
    color: "#242424",
  };
};

const contentPricing = () => {
  return {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#9A9A9A",
  };
};
