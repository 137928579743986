import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";

// components imports
import DrawerHeader from "../Common/DrawerHeader";
import DrawerMain from "./DrawerComponents/DrawerMain";

const SettingsDrawer = ({ open, onClose }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box style={{ width: "1000px", height: "calc(100% - 10em)" }}>
        {/* Header Section */}
        <DrawerHeader onClose={onClose} title={"Settings Panel"} />

        {/* Main Content Section */}
        <DrawerMain />
      </Box>
    </Drawer>
  );
};

export default SettingsDrawer;
