import { Box, Button, Typography } from "@material-ui/core";

import CardStructure from "./components/CardStructure";

// icons
import CircleCross from "../../../images/CircleCross.svg";

// import { useDispatch } from "react-redux";
// import { checkBotAddChat } from "../../../redux/actions/gpt/esgGptAction";

const InvalidInvoice = () => {
  // const dispatch = useDispatch();

  return (
    <CardStructure header={"Payment Screen"}>
      {/* main container */}
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"50vh"}
        sx={{ gap: 20 }}
      >
        <img
          src={CircleCross}
          alt="circleTick"
          style={{ height: 100, width: 100 }}
        />

        <Typography
          style={{
            textAlign: "center",
            color: "#15314E",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
          }}
        >
          Invalid <br />
          Invoice ID
        </Typography>

        {/* <Button
          onClick={() => dispatch(checkBotAddChat())}
          color="primary"
          variant="contained"
        >
          Check bot
        </Button> */}
      </Box>
    </CardStructure>
  );
};
export default InvalidInvoice;
