import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Button, TextField, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CachedIcon from "@material-ui/icons/Cached";
import { makeStyles } from "@material-ui/core/styles";
import useChat from "./esgGptChat/useChat";
import sendButton from "../../../images/sendButton.png";
import { set_snack_bar } from "../../../redux/actions/snackbar/snackbar_action";
import { useDispatch, useSelector } from "react-redux";
import {
  setDisclaimer,
  setFollowupQues,
} from "../../../redux/actions/gpt/esgGptAction";
import PlanExpired from "./PlanExpired";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C0D4E9",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C0D4E9",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C0D4E9",
    },
    "& .MuiOutlinedInput-input": {
      color: "#3374B9",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#3374B9",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#3374B9",
    },
    "& .MuiInputLabel-outlined": {
      color: "#3374B9",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#3374B9",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#3374B9",
    },
    color: "#3374B9",
    backgroundColor: "#F9F9F9",
    borderRadius: "8px",
  },
});

function EsgGptChatField({ selectedCard }) {
  const { handleChats } = useChat();
  const { followUpQues, newOrganization, chats } = useSelector(
    (state) => state.esgGptReducer
  );
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = history;

  const showAddButton = location.pathname === "/esg_gpt/chat";

  const [question, setQuestion] = useState();

  const chat_tokens = JSON.parse(localStorage.getItem("chat_tokens"));

  // plan expired states
  const [planExpired, setPlanExpired] = useState(false);

  const togglePlanExpired = () => {
    setPlanExpired(!planExpired);
  };

  const handleQuestion = (e) => {
    setQuestion(e.target.value);

    if (JSON.parse(localStorage.getItem("isDisclaimer"))) {
      dispatch(setDisclaimer(true));
    }
  };

  const handleConversations = () => {
    if (!chat_tokens || chat_tokens <= 0) {
      setPlanExpired(true);
    } else if (question) {
      const companyName = newOrganization?.name;
      handleChats(question, companyName);

      setQuestion("");
    } else {
      dispatch(
        set_snack_bar(
          true,
          "Please Enter your question or Select a Suggested Question"
        )
      );
    }
  };

  useEffect(() => {
    setQuestion(followUpQues);
  }, [followUpQues]);

  useEffect(() => {
    dispatch(setFollowupQues(""));
  }, []);

  // ------------------ useEffect ------------------

  useEffect(() => {
    if (selectedCard) {
      if (selectedCard === "discover") {
        setQuestion("Discover the ESG Details of [Company Name]");
      } else if (selectedCard === "engage") {
        setQuestion("Engage in getting to know about ESG");
      } else {
        setQuestion("Compare ESG KPI's of [Company A] and [Company B]");
      }
    }
  }, [selectedCard]);

  // -----------------------------------------------

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {planExpired && (
          <PlanExpired isOpen={planExpired} handleClose={togglePlanExpired} />
        )}
        {showAddButton && (
          <IconButton
            style={{ color: "#3374B9", marginRight: "10px" }}
            aria-label="add to shopping cart"
          >
            <Icon style={{ color: "#3374B9" }}>add</Icon>
          </IconButton>
        )}
        <TextField
          className={classes.root}
          id="outlined-basic"
          label="When asking a question, please be specific and use the names of the companies involved"
          variant="outlined"
          style={{
            width: "70%",
            // margin: "2px 5px",
          }}
          size="small"
          value={question}
          onChange={handleQuestion}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.keyCode === 13) {
              handleConversations();
            }
          }}
          focused={question !== ""}
        />

        <img
          onClick={handleConversations}
          src={sendButton}
          alt="send"
          style={{ width: "60px", marginLeft: "10px", cursor: "pointer" }}
        />
      </div>
      <Typography
        style={{
          fontFamily: "poppins",
          fontSize: "12px",
          fontWeight: 400,
          color: "#878787",
          marginTop: "5px",
          textAlign: "center",
        }}
      >
        ESG Wise may display inaccurate information, including details about
        individuals, so please double-check its responses
      </Typography>
    </>
  );
}

export default EsgGptChatField;
