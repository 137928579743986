import { useState } from "react";
import {
  AppBar,
  Box,
  createTheme,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
} from "@material-ui/core";

// components
import { AccountsTab, ProfileTab, PlansTab } from "./Tabs";

// tabs
const SettingsTabs = [
  // { name: "Accounts", comp: <AccountsTab /> },
  { name: "Profile", comp: <ProfileTab /> },
  { name: "Plans", comp: <PlansTab /> },
];

const DrawerMain = () => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box height={"100%"}>
      <MuiThemeProvider theme={appTheme}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.AppBar}
          style={{ position: "sticky" }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            className={classes.tabs}
          >
            {SettingsTabs.map((tab, index) => (
              <Tab key={index} className={classes.tab} label={tab.name} />
            ))}
          </Tabs>
        </AppBar>
      </MuiThemeProvider>

      <Box padding={2} height={"100%"}>
        {SettingsTabs[selectedTab].comp}
      </Box>
    </Box>
  );
};

export default DrawerMain;

// --------- stylings ---------
const appTheme = createTheme({
  palette: {
    secondary: {
      main: "#32A05F",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  palette: {
    secondary: {
      main: "#32A05F",
    },
  },
  AppBar: {
    boxShadow: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },

  tab: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 800,
    minWidth: "30px",
    textTransform: "capitalize",
    padding: "6px 10px",
    marginLeft: 20,
    color: "#BBBBBB",
    "&.Mui-selected": {
      backgroundColor: "#FEFEFE",
      color: "#154328",
    },
  },
  tabs: {
    minWidth: "max-content",
    minHeight: "5px",
  },
}));
