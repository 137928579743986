import UNIVERSAL from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import { SET_UPDATE_PROFILE } from "../login/loginActionType";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";

// --------------------- payload actions ---------------------
// payload for updating user profile
export const setUpdateProfile = (payload) => {
  localStorage.setItem("language", payload.language);
  localStorage.setItem("appearance", payload.appearance);
  localStorage.setItem("userName", payload.name);
  localStorage.setItem("notifications", payload.notifications);
  localStorage.setItem("profile_img", payload?.profile_img || "");

  return {
    type: SET_UPDATE_PROFILE,
    payload,
  };
};

// --------------------- dispatch actions ---------------------
// update user profile
export const updateUserDetails = (token, userData) => {
  return (dispatch) => {
    dispatch(set_loader());

    const formData = new FormData();

    if (userData?.profile_img) {
      formData.append("profile_img", userData.profile_img);
    }

    const data = encrypt({
      "user-token": token,
      ...userData,
    });

    formData.append("data", data);

    return fetch(UNIVERSAL.BASEURL + "/users/update_user_details", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status) {
          dispatch(setUpdateProfile(responseJson.result));
          dispatch(set_snack_bar(true, responseJson?.message));
        } else {
          dispatch(set_snack_bar(true, responseJson?.message));
        }

        // final execution
        dispatch(unset_loader());
      });
  };
};
