import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {
  Box,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from "@material-ui/core";
import { ListItem } from "../EsgGptCompanyList";

// icons
import { FiMessageSquare } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

import star from "../../../../images/star.svg";
import archive from "../../../../images/archive.svg";
import deleteIcon from "../../../../images/deleteButtonSvg.svg";
import timeFast from "../../../../images/time-fast.svg";
import EsgGptCompanyList from "../EsgGptCompanyList";
import useHoverEffect from "../../../../customHooks/getHoveringEffects";
import {
  setChatGroups,
  viewChatGroups,
  getGptChatHIstory,
  setChatConversations,
  setSelectedChat,
  deleteChat,
  updateChatName,
  setSearchedOrg,
  setSearcheText,
  setNewCompanyDetails,
  deleteOrganizationEsgGpt,
} from "../../../../redux/actions/gpt/esgGptAction";
import useDebounce from "../../../../customHooks/useDebounce";
import { useHistory } from "react-router-dom";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export function ModifiedList() {
  const dispatch = useDispatch();

  const { chatsList, searchedOrganizations, selectedChat, newOrganization } =
    useSelector((state) => state.esgGptReducer);

  const companies = () => {
    if (searchedOrganizations.filtered?.length > 0)
      return searchedOrganizations.filtered;
    else if (
      !searchedOrganizations.text &&
      searchedOrganizations.filtered?.length === 0
    )
      return chatsList;
    else return null;
  };
  useEffect(() => {
    const filtered = chatsList.filter((item) => {
      let company = item?.group_name?.toLowerCase().trim();
      if (company?.includes(searchedOrganizations?.text?.toLowerCase().trim()))
        return item;
    });
    dispatch(setSearchedOrg(filtered));
  }, [searchedOrganizations?.text]);

  const { token } = useSelector((state) => state.login);

  const [selectedIndex, setSelectedIndex] = React.useState(
    Object.keys(selectedChat).length === 0 ? false : 0
  );

  const [selectedGroupName, setSelectedGroupName] = React.useState("");

  const [selectedId, setSelectedId] = React.useState("");

  useEffect(() => {
    dispatch(viewChatGroups(token));

    return () => {
      dispatch(setChatGroups([]));
      dispatch(setSearchedOrg([]));
      dispatch(setSearcheText(""));
    };
  }, []);

  useEffect(() => {
    selectedChat?._id &&
      dispatch(
        getGptChatHIstory(
          token,
          newOrganization?._id === undefined ? "" : newOrganization?._id,
          false,
          selectedChat?._id === undefined ? "" : selectedChat?._id
        )
      );
    return () => {
      dispatch(setChatConversations([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat?._id]);

  useEffect(() => {
    // console.log("effect", selectedChat, selectedIndex);
    setSelectedIndex((prevalue) =>
      Object.keys(selectedChat).length === 0
        ? false
        : prevalue === false
        ? 0
        : prevalue
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(selectedChat).length, selectedChat?.group_name]);

  const handleListItemClick = (event, index, chat) => {
    setSelectedIndex(index);
    dispatch(setSelectedChat(chat));
  };

  const handleDeleteChat = (id) => {
    dispatch(deleteChat(token, id));
  };

  const handleChangeName = (name, id) => {
    setSelectedGroupName(name);
    setSelectedId(id);
  };

  useDebounce(
    () => {
      // props.setBarChartCustomize(value);
      // dispatch(updateChatName(token, selectedId, selectedGroupName));
      setSelectedGroupName("");
      setSelectedId("");
    },
    10000,
    [selectedGroupName]
  );

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      setSelectedIndex(false);

      dispatch(updateChatName(token, selectedId, selectedGroupName));
      setSelectedGroupName("");
      setSelectedId("");
    }
    // console.log( e.key)
  }

  // action options for generic
  const [genericOpts, setGenericOpts] = useState(null);
  const [selectedMenuChat, setSelectedMenuChat] = useState(null);

  const handleMenuClick = (event, chat, isCurrent) => {
    if (isCurrent) {
      setGenericOpts(event.currentTarget);
      setSelectedMenuChat(chat);
    }
  };

  const handleMenuClose = () => {
    setGenericOpts(null);
  };

  return (
    <List style={{ padding: "8px 12px 8px 0px" }}>
      {companies() ? (
        companies().map((chat, i) => (
          <ListItem
            key={chat?._id}
            button={false}
            style={{ borderRadius: "8px", margin: "5px" }}
            selected={selectedChat?._id === chat?._id}
            onClick={(event) => handleListItemClick(event, i, chat)}
          >
            {/* start icon */}
            <FiMessageSquare
              color={"#3374B9"}
              size={20}
              style={{ marginRight: 10 }}
            />

            {/* text */}
            <ListItemText
              primary={
                selectedId === chat?._id ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    onChange={(e) => setSelectedGroupName(e.target.value)}
                    onKeyUp={(e) => handleKeyPress(e)}
                    value={selectedGroupName}
                    InputProps={{
                      style: {
                        backgroundColor: "#FCFCFC",
                        borderRadius: "8px",
                      },
                    }}
                  />
                ) : (
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: selectedChat?._id === chat?._id ? 600 : 400,
                      color: "#15314E",
                    }}
                  >
                    {chat?.group_name}
                  </Typography>
                )
              }
              style={{
                cursor: selectedChat?._id === chat?._id ? "default" : "pointer",
              }}
            />

            {/* actions */}
            {selectedChat?._id === chat?._id && (
              <ListItemSecondaryAction>
                {selectedId !== chat?._id && (
                  <>
                    <Box
                      style={{
                        marginLeft: "auo",
                        backgroundColor:
                          selectedChat?._id === chat?._id ? "#EBF1F8" : "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 32,
                        height: 32,
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={(e) =>
                        handleMenuClick(
                          e,
                          chat,
                          selectedChat?._id === chat?._id
                        )
                      }
                    >
                      <IconButton
                        size="small"
                        disableRipple={selectedIndex !== i}
                      >
                        <BsThreeDotsVertical color={"#3374B9"} />
                      </IconButton>
                    </Box>

                    <Menu
                      anchorEl={genericOpts}
                      open={Boolean(genericOpts)}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      getContentAnchorEl={null}
                      variant="menu"
                      PaperProps={{ style: { boxShadow: "none" } }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleChangeName(
                            selectedMenuChat?.group_name,
                            selectedMenuChat?._id
                          );
                          handleMenuClose();
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDeleteChat(selectedMenuChat?._id);
                          handleMenuClose();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </>
                )}

                {/* {selectedId !== chat?._id && (
                <IconButton
                  variant="outlined"
                  disabled={selectedIndex !== i}
                  style={{
                    color: "#3374B9",
                    marginLeft: "auto",
                    padding: 5,
                  }}
                  aria-label="add to shopping cart"
                  onClick={() => handleChangeName(chat?.group_name, chat?._id)}
                >
                  <EditOutlinedIcon
                    style={{
                      color: "#3374B9",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  />
                </IconButton>
              )}
              <IconButton
                style={{
                  color: "#3374B9",
                  marginLeft: "auto",
                  padding: 5,
                }}
                aria-label="add to shopping cart"
                onClick={() => handleDeleteChat(chat?._id)}
                disabled={selectedIndex !== i}
              >
                <DeleteOutlineOutlinedIcon
                  style={{
                    color: "#3374B9",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </IconButton> */}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))
      ) : (
        <Typography style={{ textAlign: "center", fontSize: 14 }}>
          No Data Found.
        </Typography>
      )}
    </List>
  );
}

export default function EsgGptUpdatedList() {
  const { selectedItem, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  const history = useHistory();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedID, setSelectedId] = useState(0);
  const { organizations, searchedOrganizations, newOrganization } = useSelector(
    (state) => state.esgGptReducer
  );
  const { token } = useSelector((state) => state.login);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  React.useEffect(() => {
    !newOrganization?._id && organizations?.favorites
      ? setSelectedId(organizations?.favorites[0]?._id)
      : setSelectedId(newOrganization?._id);
    !newOrganization?._id &&
      organizations?.favorites &&
      dispatch(setNewCompanyDetails(organizations?.favorites[0]));
    // console.log("ser", organizations?.favorites);
  }, [organizations?.favorites]);

  const handleListItemClick = (event, index, organization) => {
    window.location.pathname !== "/esg_gpt/company_profiles" &&
      history.push("/esg_gpt/company_profiles");
    setSelectedId(index);
    dispatch(setNewCompanyDetails(organization));
    // console.log(index, organization);
  };

  useEffect(() => {
    return () => {
      setSelectedId(0);
    };
  }, []);
  return (
    <div>
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{ border: "1px solid white" }}
      > */}
      {/* <AccordionSummary */}
      {/* expandIcon={<ExpandMoreIcon style={{ color: "#676767" }} />}
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ backgroundColor: "#F6F6F6" }}
        > */}
      {/* <img src={star} style={{ marginRight: "7px" }} alt="pdf icon" /> */}

      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#9A9A9A",
          padding: "10px",
        }}
      >
        My Listing
      </Typography>
      {/* </AccordionSummary> */}
      {/* <AccordionDetails style={{ padding: "5px" }}> */}
      <List style={{ width: "100%" }}>
        {organizations?.favorites?.map((company, index) => (
          <ListItem
            style={{
              borderRadius: "8px",
              backgroundColor: selectedID === company?._id && "#C0D4E9",
              // margin: "5px 0px",
              width: "100%",
            }}
            // selected={selectedID === company?._id }
            onClick={(event) =>
              handleListItemClick(event, company?._id, company)
            }
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: selectedID === company?._id ? 500 : 400,
                  }}
                >
                  {company?.name}
                </Typography>
              }
              style={{ cursor: "pointer" }}
              // secondary={secondary ? "Secondary text" : null}
            />
            <ListItemSecondaryAction
              style={{
                width: "25%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={timeFast}
                style={{ cursor: "pointer" }}
                alt="pdf icon"
              />
              <IconButton
                style={{
                  color: "#3374B9",
                  marginLeft: "auto",
                  backgroundColor: "#FBFBFB",
                  boxShadow:
                    selectedItem === index
                      ? "1px 1px 4px 1px  #B1D4E0"
                      : "1px 1px 4px 1px  #EDF2F3",
                  borderRadius: "10px",
                  height: "35px",
                  // marginRight: "15px",
                  padding: "5px",
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                onClick={() => {
                  dispatch(deleteOrganizationEsgGpt(token, company._id));
                }}
                aria-label="add to shopping cart"
              >
                <DeleteOutlineOutlinedIcon
                  style={{
                    color: "#3374B9",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {/* </AccordionDetails> */}
      {/* </Accordion> */}
      {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        elevation={0}
        style={{ border: "1px solid white", margin: "8px 0px" }}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={
            <ExpandMoreIcon style={{ color: "#676767", padding: 0 }} />
          }
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <img src={archive} style={{ marginRight: "7px" }} alt="pdf icon" /> */}

      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#9A9A9A",
          padding: "10px",
        }}
      >
        Library
      </Typography>
      {/* </AccordionSummary> */}
      {/* <AccordionDetails
          style={{ borderBottom: "1px solid white", padding: "0px" }}
        > */}
      {/* {expanded === "panel2" && ( */}
      <div
        // className="scroll"
        style={{ height: window.height > 1000 ? "auto" : "40vh" }}
      >
        <EsgGptCompanyList handleSelectedId={() => setSelectedId(null)} />
      </div>
      {/* // )} */}
      {/* </AccordionDetails> */}
      {/* </Accordion> */}
    </div>
  );
}

// old code
//      <div>
{
  /* <Accordion
expanded={expanded === "panel1"}
onChange={handleChange("panel1")}
style={{ border: "1px solid white" }}
>
<AccordionSummary
  expandIcon={<ExpandMoreIcon style={{ color: "#676767" }} />}
  aria-controls="panel1d-content"
  id="panel1d-header"
  style={{ backgroundColor: "#F6F6F6" }}
>
  <img src={star} style={{ marginRight: "7px" }} alt="pdf icon" />

  <Typography
    style={{ fontSize: "14px", fontWeight: 500, color: "#6C6C6C" }}
  >
    My Favorites
  </Typography>
</AccordionSummary>
<AccordionDetails style={{ padding: "5px" }}>
  <List style={{ width: "100%" }}>
    {organizations?.favorites?.map((company, index) => (
      <ListItem
        style={{
          borderRadius: "8px",
          backgroundColor: selectedID === company?._id && "#EBF1F8",
          margin: "5px 0px",
          width: "100%",
        }}
        // selected={selectedID === company?._id }
        onClick={(event) =>
          handleListItemClick(event, company?._id, company)
        }
      >
        <ListItemText
          primary={
            <Typography
              style={{
                fontSize: 13,
                fontWeight: selectedID === company?._id ? 500 : 400,
              }}
            >
              {company?.name}
            </Typography>
          }
          style={{ cursor: "pointer" }}
          // secondary={secondary ? "Secondary text" : null}
        />
        <ListItemSecondaryAction
          style={{
            width: "25%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            src={timeFast}
            style={{ cursor: "pointer" }}
            alt="pdf icon"
          />
          <IconButton
            style={{
              color: "#3374B9",
              marginLeft: "auto",
              backgroundColor: "#FBFBFB",
              boxShadow:
                selectedItem === index
                  ? "1px 1px 4px 1px  #B1D4E0"
                  : "1px 1px 4px 1px  #EDF2F3",
              borderRadius: "10px",
              height: "35px",
              // marginRight: "15px",
              padding: "5px",
            }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            onClick={() => {
              dispatch(deleteOrganizationEsgGpt(token, company._id));
            }}
            aria-label="add to shopping cart"
          >
            <DeleteOutlineOutlinedIcon
              style={{
                color: "#3374B9",
                cursor: "pointer",
                fontSize: "20px",
              }}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
</AccordionDetails>
</Accordion>
<Accordion
expanded={expanded === "panel2"}
onChange={handleChange("panel2")}
elevation={0}
style={{ border: "1px solid white", margin: "8px 0px" }}
>
<AccordionSummary
  aria-controls="panel2d-content"
  id="panel2d-header"
  expandIcon={
    <ExpandMoreIcon style={{ color: "#676767", padding: 0 }} />
  }
  style={{ backgroundColor: "#F6F6F6" }}
>
  <img src={archive} style={{ marginRight: "7px" }} alt="pdf icon" />

  <Typography
    style={{ fontSize: "14px", fontWeight: 500, color: "#6C6C6C" }}
  >
    Repository
  </Typography>
</AccordionSummary>
<AccordionDetails
  style={{ borderBottom: "1px solid white", padding: "0px" }}
>
  {expanded === "panel2" && (
    <div
      className="scroll"
      style={{ height: window.height > 1000 ? "auto" : "40vh" }}
    >
      <EsgGptCompanyList />
    </div>
  )}
</AccordionDetails>
</Accordion>
</div> */
}
