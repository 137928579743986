import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

// icons
import CloseIcon from "@material-ui/icons/Close";
import GroupIcon from "../../../../images/Group.svg";
import CustomButton from "../../../UI/button/button";

const TokenDetails = ({ onClose, open, heading, content, openToken }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ fontWeight: "bold", fontSize: 15 }}>
            Tokens
          </Typography>
          <CloseIcon onClick={onClose} cursor="pointer" />
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={GroupIcon} alt="" />
          <Typography
            style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
          >
            1
          </Typography>
          <Typography
            style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
          >
            {`=`}
          </Typography>
          <Typography
            style={{ fontWeight: "bold", fontSize: 16, padding: "0px 5px" }}
          >
            1 Questions
          </Typography>
        </div>
        <Typography style={{ fontSize: 13, marginTop: 25, paddingBottom: 25,  }}>
          Each token serves as a question, allowing you to inquire about
          companies' ESG practices or any other information with our chatbot.
        </Typography>
        <CustomButton
          variant={"contained"}
          color={"primary"}
          // fullWidth
          size={"small"}
          style={{ textTransform: "capitalize",marginLeft:"70%" }}
          onClick={() => {
            openToken();
            onClose()
          }}
        >
          Upgrade to plus plan
        </CustomButton>
      </DialogContent>
    </Dialog>
  );
};

export default TokenDetails;
