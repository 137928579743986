import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Divider from "@material-ui/core/Divider";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { getSectorIndustries } from "../../../../redux/actions/brm/BrmActions";
import { createOrganizationEsgGpt } from "../../../../redux/actions/gpt/esgGptAction";
import CustomTextField from "../../../UI/textfield/textfield";

import countries from "../../../../utils/countries/countries";
import CustomButton from "../../../UI/button/button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const TurnoverYearPickerDialog = ({
  isOpen,
  onCancel,
  onSet,
  start,
  end,
  onYearBack,
  onYearForward,
  onYearClick,
  onClear,
  range,
  yearRange,
}) => {
  return (
    <>
      <Dialog maxWidth="lg" open={isOpen}>
        <DialogContent style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`${start || ""}-${end || ""}`}</Typography>
            <Typography
              onClick={onYearBack}
              style={{
                fontSize: "22px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`<`}</Typography>
            <Typography
              onClick={onYearForward}
              style={{
                fontSize: "22px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`>`}</Typography>
            <Typography
              onClick={onClear}
              style={{
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
                float: "right",
              }}
            >{`Clear`}</Typography>
          </div>
          <div>
            <Grid
              container
              xs={12}
              md={12}
              gap={1}
              spacing={2}
              style={{
                padding: "2% 1% 4% 1%",
                // // marginLeft: '2%',
              }}
            >
              {yearRange.map((year) => {
                return (
                  <Grid
                    item
                    xs={4}
                    md={4}
                    align="center"
                    style={{
                      // margin:'6px 8px',
                      // border: "1px solid blue",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      onClick={() => onYearClick(year)}
                      style={{
                        fontWeight: 500,
                        padding: "8px 5px",
                        border: range(year) ? "1px solid #3374B9" : "none",
                        borderRadius: "5px",
                        backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                      }}
                    >
                      {year}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            // variant="outlined"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={onCancel}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            style={{ padding: "6px 20px", textTransform: "none" }}
            disabled={!start || !end}
            onClick={onSet}
          >
            Set Period
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

function EsgGptFormField() {
  const classes = useStyles();

  const dispath = useDispatch();

  const login = useSelector((state) => state.login);

  const { sectorIndustries } = useSelector((state) => state.brmData);

  const { newLogo } = useSelector((state) => state.esgGptReducer);

  const [sector, setSector] = React.useState("");
  const [sectorToSend, setSectorToSend] = useState(null);

  const [filterIndustry, setFilterIndustry] = React.useState([]);
  const [industryToSend, setIndustryToSend] = useState(null);

  const [industry, setIndustry] = React.useState({
    industry: "",
    industry_code: "",
  });

  const [companyDetails, setCompanyDetails] = React.useState({
    name: "",
    isin: "",
    url: "",
    cin: "",
    gst: "",
    image: "",
  });

  const [turnoverPicker, setTurnoverPicker] = React.useState(false);

  const [revenueData, setRevenueData] = useState([
    { revenue: "", year: "", currency: "", figures: "" },
  ]);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  const [country, setCountry] = useState("");

  const currentIndex = React.useRef({
    i: 0,
    revenue: true,
  });
  const handleSectorChange = (event) => {
    const targetValue = event.target.value;
    const selectedSectorId = event.currentTarget.getAttribute("data-id");

    setSector(targetValue);
    setSectorToSend(selectedSectorId);

    const filterIndustr = sectorIndustries.find(
      (value) => value.Sector === targetValue
    );

    filterIndustr !== undefined && filterIndustr.industry_data
      ? setFilterIndustry(filterIndustr?.industry_data)
      : setFilterIndustry([]);
  };

  const handleIndustryChange = (event, industry) => {
    const targetValue = event.target.value;
    const selectedIndustryId = event.currentTarget.getAttribute("data-id");

    setIndustryToSend(selectedIndustryId);

    const findIndustryCode = industry.find((v) => v.Industry === targetValue);

    setIndustry((preValue) => ({
      ...preValue,
      industry: targetValue,
      industry_code: findIndustryCode?.industry_code,
    }));
  };

  const handleCompanyDetails = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setCompanyDetails((preValue) => ({
      ...preValue,
      [targetName]: targetValue,
    }));
  };

  const handleDetailSubmission = () => {
    dispath(
      createOrganizationEsgGpt(
        login.token,
        companyDetails.name,
        companyDetails.isin,
        sectorToSend,
        industryToSend,
        industry.industry_code,
        newLogo,
        country,
        revenueData
      )
    );
  };

  React.useEffect(() => {
    dispath(getSectorIndustries(login.token));
  }, []);

  const infoArr = [
    { label: "Enter Company Name", name: "name" },
    { label: "ISIN No", name: "isin" },
    { label: "Enter Company URL", name: "url" },
    { label: "CIN No", name: "cin" },
    { label: "GST No", name: "gst" },
  ];

  const infoAddressArr = [
    { label: "Operation Address 1", name: "Op_Add_1" },
    { label: "Operation Address 2", name: "Op_Add_2" },
    { label: "City/State", name: "city" },
    { label: "Pincode", name: "pin" },
  ];

  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      // console.log("year", year, tstartYear, endYear);
      setTStartYear(year);
      setTEndYear("");
      // console.log("setTEndYear 2");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
      // console.log("setTEndYear 3");
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
      // console.log("setEndYear 4");
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i, revenue) => {
    // console.log('revenue',revenue)
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };
  // console.log(revenueData,turnoverData)
  const handleSetYear = () => {
    currentIndex.current.revenue &&
      setRevenueData((prev) => {
        let copy = [...prev];
        copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        return copy;
      });

    // setCompanyDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };
  React.useEffect(() => {
    const data = [];
    if (true) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (companyDetails.yearOfAssessment) {
      for (let i = 0; i < 9; i++) {
        data.push(companyDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, []);

  return (
    <>
      {turnoverPicker && (
        <TurnoverYearPickerDialog
          isOpen={turnoverPicker}
          onCancel={handleCancelYear}
          onSet={handleSetYear}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={tstartYear}
          end={tendYear}
          yearRange={yearRange}
          onYearClick={handleTurnoverYearClick}
          range={trange}
          onClear={() => {
            setTEndYear("");
            setTStartYear("");
          }}
        />
      )}
      <div
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          // border:"solid red",
          overflow: "scroll",
        }}
      >
        <Typography
          style={{
            color: "#15314E",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            padding: "10px",
          }}
        >
          Basic Details
        </Typography>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          {infoArr.map(({ label, name }, i) => (
            <TextField
              style={{ width: "48%", marginTop: "5px" }}
              size="small"
              id="outlined-basic"
              label={label}
              variant="outlined"
              name={name}
              onChange={handleCompanyDetails}
              required={i === 0 || i === 1 ? true : false}
            />
          ))}
          <Autocomplete
            id="country-select-demo"
            size="small"
            style={{ width: "48%" }}
            // value={{
            //   country_name: organizaitonDetailsObject?.country_name,
            // }}
            options={countries}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>{option.label}</React.Fragment>
            )}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label="Choose a country"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                required
              />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setCountry(newValue?.code);
                // setBranchCountry(newValue);
              }
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "48%" }}>
            <Typography
              style={{
                color: "#15314E",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                padding: "10px",
              }}
            >
              Sector
            </Typography>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "10px", height: 40 }}
                required
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Sector
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={sector}
                  style={{ height: "42px", marginTop: "8px" }}
                  size="small"
                  onChange={handleSectorChange}
                  label="Sector"
                >
                  {sectorIndustries.map(({ _id, Sector }) => (
                    <MenuItem key={_id} value={Sector} data-id={_id}>
                      {Sector}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ width: "48%" }}>
            <Typography
              style={{
                color: "#15314E",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                padding: "10px",
              }}
            >
              Industry
            </Typography>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "10px" }}
                required
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Industry
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={industry?.industry}
                  size="small"
                  style={{ height: "42px", marginTop: "8px" }}
                  onChange={(e) => handleIndustryChange(e, filterIndustry)}
                  label="Industry"
                >
                  {filterIndustry.map(({ _id, Industry, industry_code }) => (
                    <MenuItem key={_id} value={Industry} data-id={_id}>
                      {Industry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <Typography
          style={{
            color: "#15314E",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            padding: "10px",
          }}
        >
          Revenue
        </Typography>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          <TextField
            style={{ width: "48%", marginTop: "5px" }}
            size="small"
            id="outlined-basic"
            label={"Revenue"}
            variant="outlined"
            value={revenueData[0].revenue}
            // name={name}
            onChange={(e) =>
              setRevenueData([{ ...revenueData[0], revenue: e.target.value }])
            }
            required
          />
          <TextField
            style={{ width: "48%", marginTop: "5px" }}
            size="small"
            id="outlined-basic"
            label={"Year"}
            variant="outlined"
            value={revenueData[0].year}
            // name={name}
            onChange={(e) =>
              setRevenueData([{ ...revenueData[0], year: e.target.value }])
            }
            onClick={() => handleTurnover(0, true)}
            required
            // required={i === 0 || i === 1 ? true : false}
          />
          <Autocomplete
            id="country-select-demo"
            size="small"
            options={["USD", "AED", "INR"]}
            classes={{
              endAdornment: classes.icon,
              root: {},
            }}
            style={{ width: "48%" }}
            value={revenueData[0].currency}
            // inputProps={{
            //   classes: {
            //     icon: classes.icon,
            //     root:{}
            //   },
            // }}
            autoHighlight
            getOptionLabel={(option) => `${option}`}
            renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label="Currency"
                variant="outlined"
                value={revenueData[0].currency}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                required
              />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setRevenueData([{ ...revenueData[0], currency: newValue }]);

                // setBranchCountry(newValue);
              }
            }}
          />
          <Autocomplete
            id="country-select-demo"
            size="small"
            options={["Million", "Billion", "Crore"]}
            classes={{
              endAdornment: classes.icon,
              root: {},
            }}
            style={{ width: "48%" }}
            value={revenueData[0].figures}
            // inputProps={{
            //   classes: {
            //     icon: classes.icon,
            //     root:{}
            //   },
            // }}
            autoHighlight
            getOptionLabel={(option) => `${option}`}
            renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label="Figures"
                variant="outlined"
                value={revenueData[0].figures}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                required
              />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setRevenueData([{ ...revenueData[0], figures: newValue }]);

                // setBranchCountry(newValue);
              }
            }}
          />
        </div>

        <Typography
          style={{
            color: "#15314E",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            padding: "10px",
          }}
        >
          Geography (If available)
        </Typography>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          {infoAddressArr.map(({ label, name }) => (
            <TextField
              style={{ width: "48%", marginTop: "5px" }}
              size="small"
              id="outlined-basic"
              label={label}
              variant="outlined"
              name={name}
            />
          ))}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "60px",
            marginTop: "20px",
          }}
        >
          <Button
            Button
            variant="contained"
            color="primary"
            disabled={
              !companyDetails.name ||
              !companyDetails.isin ||
              !sector ||
              !industry?.industry ||
              !country ||
              !revenueData[0]?.revenue ||
              !revenueData[0]?.year ||
              !revenueData[0]?.currency ||
              !revenueData[0]?.figures
            }
            style={{
              // backgroundColor:
              //   companyDetails.name || companyDetails.isin
              //     ? "#3374B9"
              //     : "#bababa",
              borderRadius: "8px",
              // color: "#EBF1F8",
              width: "48%",
              marginLeft: "auto",
              maxWidth: "35em",
              textTransform: "capitalize",
            }}
            onClick={handleDetailSubmission}
            // className={classes.button}
          >
            Acquire Details
          </Button>
        </div>
      </div>
    </>
  );
}

export default EsgGptFormField;
