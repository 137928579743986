import { useEffect, useState } from "react";
import { Box, Grid, Slide, Typography } from "@material-ui/core";

import tick from "../../../images/tick.png";
import logoWhite from "../../../images/ImpactGrows White Corrected.svg";

import signupStyles from "../signup/signupStyles";
import LeftNotShrink from "./components/LeftNotShrink";
import LeftShrink from "./components/LeftShrink";
import "../signup/signup.css";

import { useSelector } from "react-redux";
import PaymentComplete from "../../widgets/completePaymentUIs/PaymentComplete";
import InvalidInvoice from "../../widgets/completePaymentUIs/InvalidInvoice";
import ProceedToPay from "../../widgets/completePaymentUIs/ProceedToPay";

const CompletePaymentMain = () => {
  // from redux
  const { uaInvoiceData } = useSelector((state) => state.esgGptReducer);

  const classes = signupStyles();

  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShrink(true);
    }, 500);

    return () => {
      setShrink(false);
    };
  }, []);

  const subCard = (details) => {
    return (
      <div style={{ display: "flex", padding: "10px 0px" }}>
        <img alt="" src={tick} width="20px" height="20px" />
        <Typography className={classes.sideBarSubTitle}>
          {details || ""}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.mainDiv}>
      {/* main ImGrows logo */}
      <div className={classes.mainDiv1}>
        <img src={logoWhite} alt="logo" />
      </div>

      <Grid container lassName={classes.mainGrid}>
        {/* Left Side */}
        <Grid
          container
          item
          className={
            shrink
              ? `blueDivShrinked ${shrink ? "blueDivAnimation" : ""}`
              : `blueDiv ${shrink ? "blueDivAnimation" : ""}`
          }
          style={{ zIndex: "2" }}
        >
          {!shrink && <LeftNotShrink classes={classes} subCard={subCard} />}
          {shrink && <LeftShrink classes={classes} subCard={subCard} />}
        </Grid>

        {/* Right Side */}
        <Grid item container justifyContent="center" alignItems="center" xs={9}>
          <Grid item xs={7} sm={7} md={7}>
            {!uaInvoiceData && <InvalidInvoice />}

            {uaInvoiceData && uaInvoiceData?.is_paid && <PaymentComplete />}

            {uaInvoiceData && !uaInvoiceData?.is_paid && <ProceedToPay />}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default CompletePaymentMain;
