var UNIVERSAL = {
  BASEURL: process.env.React_App_BASEURL,
  CAPTCHA_SITE_KEY:
    process.env.CAPTCHA_SITE_KEY || "6LcXjWApAAAAAGIr3aKlLe98ze9EVJh500dv8UKH",
  SKIP_CAPTCHA: process.env.REACT_APP_SKIP_CAPTCHA
    ? JSON.parse(process.env.REACT_APP_SKIP_CAPTCHA)
    : false,
};

export default UNIVERSAL;
