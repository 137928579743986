import Box from "@material-ui/core/Box";

const ImageCard = ({ content }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"10em"}
    >
      <img
        src={content}
        alt="Card"
        style={{ width: "10em", height: "10em", objectFit: "contain" }}
      />
    </Box>
  );
};
export default ImageCard;
