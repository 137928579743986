import React from "react";

import { Typography } from "@material-ui/core";
import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";
import LockIcon from "../../../images/lockIcon.png";
import { LockIconDialog } from "../esgGpt/esgGptCompanyProfile/EsgGptCompany/CompanyInfoComps/ScoreCard";

export const TopicHeader = ({ topic, score, is_premium, hasLogo = true,children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: "1px solid lightblue",
        marginTop: "16px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {hasLogo && (
          <img
            style={{ width: "40px", height: "40px" }}
            src={getColoredLogo(topic)}
            alt="leaf"
          />
        )}
        {children}
        <Typography style={{ fontSize: "13px", fontWeight: 600, marginLeft:"10px" }}>
          {topic}
        </Typography>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {score}
        </Typography>
      </div>
    </div>
  );
};
export const TopicData = ({ topic, score, is_premium }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "3.5%",
        // border:"solid",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          style={{ fontSize: "13px", marginLeft: "5px", fontWeight: 400 }}
        >
          {topic}
        </Typography>
      </div>
      <div>
        {is_premium ? (
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              filter: is_premium ? "blur(0px)" : "blur(5px)",
            }}
          >
            {score}
          </Typography>
        ) : (
          <LockIconDialog/>
    
        )}
      </div>
    </div>
  );
};

export const data = [
  {
    topic: "Environment",
    score: 28,
    subTopic: [
      { topic: "Climate Change", score: 10 },
      { topic: "Water Management", score: 6 },
      { topic: "Biodiversity", score: 5 },
      { topic: "Pollution Control", score: 3 },
    ],
  },
  {
    topic: "Social",
    score: 20,
    subTopic: [
      { topic: "Labor Rights", score: 6 },
      { topic: "Human Rights", score: 5 },
      { topic: "Community Development", score: 4 },
      { topic: "Diversity and Inclusion", score: 5 },
    ],
  },
  {
    topic: "Governance",
    score: 15,
    subTopic: [
      { topic: "Board Independence", score: 4 },
      { topic: "Executive Compensation", score: 3 },
      { topic: "Shareholder Rights", score: 3 },
      { topic: "Risk Management", score: 2 },
    ],
  },
  {
    topic: "Business",
    score: 30,
    subTopic: [
      { topic: "Product Quality and Safety", score: 10 },
      { topic: "Customer Privacy and Data Protection", score: 6 },
      { topic: "Supply Chain Management", score: 28 },
      { topic: "Marketing and Advertising", score: 25 },
      { topic: "Innovation and Intellectual Property", score: 3 },
    ],
  },
  {
    topic: "Human Capital",
    score: 7,
    subTopic: [
      { topic: "Work-Life Balance", score: 2 },
      { topic: "Employment Training & Development", score: 2 },
      { topic: "Diversity and Inclusion", score: 1 },
      { topic: "Employee engagement", score: 2 },
    ],
  },
];
