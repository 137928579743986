import React from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
// import Textfield from "../../UI/textfield/textfield";
import { CiFilter } from "react-icons/ci";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearcheText,
  viewOrganizationEsgGpt,
} from "../../../redux/actions/gpt/esgGptAction";
import search from "../../../images/search.png";
import _debounce from "lodash/debounce"; // Import debounce from lodash
import CustomButton from "../../UI/button/button";
import EsgGptFilterMenu from "./EsgGptFilterMenu";

const useStyles = makeStyles({
  notchedOutline: {
    border: "none",
    borderColor: "red",
    fontSize: "12px", // Change border color to red
  },
  inputText: {
    fontFamily: "Poppins", // Apply font family to input text
  },
  label: {
    fontFamily: "Poppins", // Apply font family to label
  },
  resize: {
    fontSize: 15,
  },
});

export default function EsgGptSearchBar({ EsgCompanyList }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Debounce the handleSearch function
  const debouncedSearch = _debounce((value) => {
    dispatch(setSearcheText(value));
    if (value !== "") {
      dispatch(viewOrganizationEsgGpt(login.token, 10, 0, value));
    } else {
      dispatch(viewOrganizationEsgGpt(login.token, 10, 0));
    }
  }, 800);

  const handleSearch = (value) => {
    // Call the debouncedSearch function
    debouncedSearch(value);
  };
  const companyLocation = window.location.pathname.includes("company_profiles");
  return (
    <>
      {anchorEl && (
        <EsgGptFilterMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        />
      )}
      <div
        style={{
          width: "100%",
          marginTop: "15px",
          marginBottom: "-5px",
          borderRadius: "5px",
          // border: "1px solid #E9E9E9",
          fontFamily: "poppins",
          display: "flex",
          gap: 4,
        }}
      >
        <TextField
          name="Search"
          variant="outlined"
          fullWidth
          size="small"
          type="text"
          label={companyLocation ? "Search By Company" : "Search by Name"}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          style={{
            backgroundColor: "#FCFCFC",
            borderRadius: "8px",
            fontFamily: "Poppins",
            fontSize: "8px",
          }}
          InputLabelProps={{
            style: {
              fontSize: "15px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img sizes="small" src={search} alt="searchIcon" />
              </InputAdornment>
            ),
            classes: {
              notchedOutline: classes.notchedOutline,
              input: classes.resize,
            },
          }}
        />
        {companyLocation && (
          <IconButton
            // variant={"outlined"}
            color={"primary"}
            style={{
              // marginTop: "8px",
              textTransform: "capitalize",
              backgroundColor: "#FEFEFE",
              // border: "1px solid #3374B9",
              borderRadius: "8px",

              // height:"100%",/
              // width:"30px"
            }}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-tut="add-newCompany"
          >
            <CiFilter />
          </IconButton>
        )}
      </div>
    </>
  );
}
