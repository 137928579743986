import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import RouterCont from "./containers/router/routerCont";
import "highlight.js/styles/atom-one-light.css";
import "react-tooltip/dist/react-tooltip.css";

const theme = createTheme({
  palette: {
    warning: { main: "#C45446" },
  },
  typography: {
    fontFamily: ["poppins"].join(","),
  },
  overrides: {
    MuiTabs: {
      indicator: {
        color: "green",
        // border:"1px solid red",
        backgroundColor: "#32A05F",
      },
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterCont />
      </ThemeProvider>
    </>
  );
}

export default App;
