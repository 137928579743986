// converts 2024-04-17T04:37:27.000Z => 17 Apr 2024

const ISOtoDDMMYY = (inputDate) => {
  const date = new Date(inputDate);

  // Options for formatting the date
  const options = { day: "2-digit", month: "short", year: "numeric" };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-GB", options);

  return formattedDate;
};

export default ISOtoDDMMYY;
