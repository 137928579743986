import { Box, Typography } from "@material-ui/core";

import CardStructure from "./components/CardStructure";

// icons
import CircleTick from "../../../images/CircleTick.svg";

const PaymentComplete = () => {
  // go back to main route
  const handleBackToESG = () => {
    window.location.href = "/";
  };

  return (
    <CardStructure
      header={"Payment Screen"}
      actionLabel={"Proceed to ESG Wise"}
      handleAction={handleBackToESG}
    >
      {/* main container */}
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"50vh"}
        sx={{ gap: 40 }}
      >
        <img
          src={CircleTick}
          alt="circleTick"
          style={{ height: 100, width: 100 }}
        />

        <Typography
          style={{
            textAlign: "center",
            color: "#15314E",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
          }}
        >
          Payment Successful!
        </Typography>
      </Box>
    </CardStructure>
  );
};
export default PaymentComplete;
