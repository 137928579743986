import { Box, Grid, Typography } from "@material-ui/core";

// icons & logos
import sideleft from "../../../../images/sidebarLeft.png";
import sideright from "../../../../images/sidebarRight.png";
import AI from "../../../../images/AI.svg";

const LeftShrink = ({ classes, subCard }) => {
  return (
    <Grid container>
      <Grid
        sx={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        item
        container
        justifyContent="center"
        className={classes.grid3}
      >
        <Box display="flex" justifyContent="flex-end" paddingRight="20px">
          <img src={sideright} alt="left" className={classes.img3} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          paddingBottom="15px"
          // paddingBottom="190px"
          // paddingTop="16px"
          paddingLeft="15px"
          paddingRight="12px"
          // maxWidth="270px"
        >
          <img src={AI} alt="user" style={{ width: "80%", height: "70%" }} />

          <Typography className={classes.sideBarTitle}>
            AI-powered sustainability guide
          </Typography>
          {subCard("500+ ESG Company profiles")}
          {subCard("ESG Enabled Knowledge Base")}
          {subCard("Peer & Industry ESG Performance Benchmarking")}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          // marginTop='auto'
          paddingLeft="16px"
        >
          <img src={sideleft} alt="right" className={classes.img3} />
        </Box>
      </Grid>

      <Grid xs={12} sm={12} md={12} lg={12} className={classes.grid4}>
        <div className={classes.grid4}>
          <ul className={classes.ul}>
            <li className="footerList">
              <a className={classes.link} href="/faq" target="_blank">
                FAQ
              </a>
            </li>
            <li className="footerList">
              <a className={classes.link} href="/tnc" target="_blank">
                Terms & Conditions{" "}
              </a>
            </li>
            <li className="footerList">
              <a
                className={classes.link}
                href="/privacy_policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </Grid>
    </Grid>
  );
};
export default LeftShrink;
