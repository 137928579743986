import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  createTheme,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
} from "@material-ui/core";

// components
import AllTab from "./Tabs/AllTab";
import UnreadTab from "./Tabs/UnreadTab";
import CustomButton from "../../../../../UI/button/button";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../../../../../redux/actions/gpt/notificationsAction";

const DrawerMain = () => {
  // from redux
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const { notifications } = useSelector((state) => state.esgGptReducer);

  useEffect(() => {
    dispatch(getNotifications(token, 10, 0));
  }, []);

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <MuiThemeProvider theme={appTheme}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.AppBar}
          style={{ position: "sticky" }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab className={classes.tab} label="All" />
            {/* <Tab className={classes.tab} label="Unread" /> */}
          </Tabs>

          {/* Mark as read button */}
          {/* <CustomButton
            color={"primary"}
            style={{ textTransform: "none", marginRight: 20 }}
          >
            Mark all as read
          </CustomButton> */}
        </AppBar>
      </MuiThemeProvider>

      <Box>
        {selectedTab === 0 && <AllTab notifications={notifications} />}
        {/* {selectedTab === 1 && <UnreadTab notifications={notifications} />} */}
      </Box>
    </Box>
  );
};
export default DrawerMain;

// --------- stylings ---------
const appTheme = createTheme({
  palette: {
    secondary: {
      main: "#3374B9",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  palette: {
    secondary: {
      main: "#3374B9",
    },
  },
  AppBar: {
    boxShadow: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
  },

  tab: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 800,
    minWidth: "30px",
    textTransform: "capitalize",
    padding: "6px 10px",
    marginLeft: 20,
    color: "#3374B9",
    "&.Mui-selected": {
      backgroundColor: "#FEFEFE",
      color: "#15314E",
    },
  },
  tabs: {
    minWidth: "max-content",
    minHeight: "5px",
  },
}));
