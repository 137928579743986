import UNIVERSAL from "../../../config";
import {
  SET_INVOICE_DETAILS,
  SET_UA_INVOICE,
  SET_UA_ISPAID_STATUS,
} from "../../../constants/esgGptConstant";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";

// import ImpactGrowsImg from "../../../images/ImpactGrows_main.svg";

// initialize payment
export const initializePayment = (token) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/payments/initiate_payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          const { order_id } = responseJson;
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            name: "Premium Plan",
            description: "Complete payment to upgrade to premium plan",
            image:
              "https://www.imgrows.billionlives.in/assets/images/ImGrowsBlack-logo.png",
            order_id,
            handler: (response) => {
              const { razorpay_payment_id, razorpay_signature } = response;

              dispatch(
                verifyPayment(
                  token,
                  order_id,
                  razorpay_payment_id,
                  razorpay_signature
                )
              );
            },
            theme: {
              color: "#3399cc",
            },
          };
          const rzp1 = new window.Razorpay(options);

          rzp1.open();
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }

        // final execution
        dispatch(unset_loader());
      });
  };
};

// verify payment
export const verifyPayment = (token, order_id, payment_id, signature) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      order_id,
      payment_id,
      signature,
    });

    return fetch(UNIVERSAL.BASEURL + "/payments/verify_payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status) {
          localStorage.setItem("is_premium", true);
          await localStorage.setItem(
            "chat_tokens",
            responseJson?.updatedUser?.user?.tokens
          );
          await window.location.reload();
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }

        // final execution
        dispatch(unset_loader());
      });
  };
};

// followup function for getInvouceDetails
export const setInvoiceDetails = (payload) => {
  return {
    type: SET_INVOICE_DETAILS,
    payload,
  };
};

// get list of invoices for user
export const getInvoiceDetails = (token) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/payments/get_invoice_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status) {
          dispatch(setInvoiceDetails(responseJson));
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }

        // final execution
        dispatch(unset_loader());
      });
  };
};

// ------------ Complete Payment Actions ------------

const setUAInvoiceData = (payload) => {
  return {
    type: SET_UA_INVOICE,
    payload,
  };
};

// get invoice details
export const uaFetchInvoiceDetails = (invoice_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/payments/ua_fetch_invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ invoice_id }),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status) {
          dispatch(setUAInvoiceData(responseJson?.result));
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }

        // final execution
        dispatch(unset_loader());
      });
  };
};

export const uaInitiatePayment = (order_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    if (order_id) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        name: "Premium Plan",
        description: "Complete payment to upgrade to premium plan",
        image:
          "https://www.imgrows.billionlives.in/assets/images/ImGrowsBlack-logo.png",
        order_id,
        handler: (response) => {
          const { razorpay_payment_id, razorpay_signature } = response;

          dispatch(
            uaVerifyPayment(order_id, razorpay_payment_id, razorpay_signature)
          );
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new window.Razorpay(options);

      rzp1.open();
    } else {
      dispatch(set_snack_bar(true, "order_id is missing"));
    }

    dispatch(unset_loader());
  };
};

const setUAisPaidStaus = (payload) => {
  return {
    type: SET_UA_ISPAID_STATUS,
    payload,
  };
};

// verify payment
export const uaVerifyPayment = (order_id) => {
  return (dispatch, getState) => {
    dispatch(set_loader());

    const user_details = getState().esgGptReducer.uaInvoiceData?.user_details;

    return fetch(UNIVERSAL.BASEURL + "/payments/ua_verify_payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ order_id, user_details }),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status) {
          dispatch(setUAisPaidStaus(true));

          // check and set premium user
          const userToken = localStorage.getItem("user_token");
          const hasValidToken = userToken !== null && userToken !== undefined;

          if (hasValidToken) {
            // If valid token, set premium status and chat_tokens
            localStorage.setItem("is_premium", true);
            localStorage.setItem("chat_tokens", 500);
          }
        } else {
          dispatch(setUAisPaidStaus(false));
          dispatch(set_snack_bar(true, responseJson.message));
        }

        // final execution
        dispatch(unset_loader());
      });
  };
};

// --------------------------------------------------
