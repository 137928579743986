import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const TextCard = ({ isMain, content }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={isMain ? "8em" : "6em"}
    >
      <Typography
        style={{
          fontWeight: 600,
          fontSize: isMain ? "22px" : "16px",
          lineHeight: isMain ? "28px" : "24px",
          color: "#0F0F0F",
        }}
      >
        {content}
        {!isMain && " Revenue"}
      </Typography>
    </Box>
  );
};
export default TextCard;
