import io from "socket.io-client";

let socket;
let socketCompany;

// connecting to socket io server from backend

// socket connection for general chat
const connectSocket = (user_id, chat_id) => {
  if (user_id && chat_id) {
    // ---------- localhost version ----------
    // socket = io("http://localhost:8002", {
    //   query: `user_id=${user_id}&chat_id=${chat_id}&is_company=False&isin=""&company_name=""`,
    //   withCredentials: true,
    //   // transports: ["websocket"],
    // });

    // socket = io("http://164.52.206.63:8001", {
    //   query: `user_id=${user_id}`,
    // });

    socket = io("https://chat-esgwise.imgrows.app", {
      query: `user_id=${user_id}&chat_id=${chat_id}&is_company=False&isin=""&company_name=""`,
      withCredentials: true,
      // transports: ["websocket"],
    });

    // socket = io("http://164.52.206.76:8610", {
    //   query: `user_id=${user_id}&chat_id=${chat_id}&is_company=False`,
    //   withCredentials: true,
    //   // transports: ["websocket"],
    // });
    // socket = io("https://chat-esgwise.imgrows.app", {
    //   query: `user_id=${user_id}`,
    // });
    // socket = io("https://chat-esgwise.imgrows.app", {
    //   query: `user_id=${user_id}`,
    // });
  }
};

// socket connection for company based chat
const connectSocketCompany = (
  user_id,
  company_id,
  company_isin,
  company_name
) => {
  if (user_id && company_id && company_isin && company_name) {
    // ---------- localhost version ----------
    // socketCompany = io("http://localhost:8002", {
    //   query: `user_id=${user_id}&chat_id=${company_id}&is_company=True&isin=${company_isin}&company_name=${company_name}`,
    // });

    // socketCompany = io("http://164.52.206.63:8002", {
    //   query: `user_id=${user_id}`,
    // });

    // socketCompany = io("https://companychat-esgwise.test.imgrows.app/", {
    //   query: `user_id=${user_id}&company_id=${company_id}`,
    //   withCredentials: true,
    // });

    socketCompany = io("https://chat-esgwise.imgrows.app", {
      query: `user_id=${user_id}&chat_id=${company_id}&is_company=True&isin=${company_isin}&company_name=${company_name}`,
      withCredentials: true,
    });

    // socketCompany = io("http://164.52.206.76:8610", {
    //   query: `user_id=${user_id}&chat_id=${company_id}&is_company=True`,
    //   withCredentials: true,
    // });
    // socketCompany = io("https://companychat-esgwise.imgrows.app", {
    //   query: `user_id=${user_id}`,
    // });
    // socketCompany = io("https://companychat-esgwise.uat.imgrows.app", {
    //   query: `user_id=${user_id}`,
    // });
  }
};

export { socket, socketCompany, connectSocket, connectSocketCompany };
