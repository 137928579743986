import React from "react";
import { Box, Chip, Typography } from "@material-ui/core";

// Function to generate chips
const generateChips = (content) => {
  const { chips } = content;
  return (
    <Box display="flex" flexWrap="wrap">
      {chips.map((chip, index) => (
        <Chip
          key={index}
          label={chip}
          size="small"
          style={{
            backgroundColor: content?.css?.chipBg,
            color: content?.css?.chipColor,
            padding: "0px 2px",
            marginRight: "8px",
            marginBottom: "8px",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
          }}
        />
      ))}
    </Box>
  );
};

const ChipCard = ({ content }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width={"100%"}
      height={"8em"}
    >
      <Box>
        <Typography
          gutterBottom
          style={{
            fontWeight: 600,
            fontSize: content?.isSub ? "16px" : "22px",
            lineHeight: content?.isSub ? "24px" : "28px",
            color: content?.css?.headingColor,
          }}
        >
          {content?.heading}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex">{generateChips(content)}</Box>
        <Box>
          {content?.image && (
            <img
              src={content.image}
              alt="Chips Card"
              style={{
                width: "80px",
                height: "80px",
                marginTop: -24,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChipCard;
