import { Box, Chip, Typography } from "@material-ui/core";
import ISOtoDDMMYY from "../../../../../../../utils/dateConverters/ISOtoDDMMYY";

const AllTab = ({ notifications }) => {
  return (
    <Box width={"100%"} height={"100%"}>
      {/* Notifications */}
      {notifications?.length !== 0 &&
        notifications?.map((perDate, index) => (
          <Box key={index}>
            {/* Notification on */}
            <Box bgcolor={"#F6F6F6"} padding={"10px 20px"}>
              <Typography style={{ ...headerText("main") }}>
                {perDate?.notification_for}
              </Typography>
            </Box>

            {/* Notifications */}
            {perDate?.notifications?.map((notify) => (
              <Box
                key={notify?._id}
                padding={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderBottom={"1px solid #C0D4E9"}
              >
                {/* Chip and Text */}
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  style={{ gap: 10 }}
                >
                  <Chip
                    label={notify?.type}
                    size="small"
                    style={{
                      backgroundColor: "#EBF1F8",
                      color: "#3374B9",
                      padding: "0px 2px",
                      marginRight: "8px",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "16px",
                      width: "max-content",
                    }}
                  />

                  <Typography style={{ ...notifyText(), width: "30em" }}>
                    {notify?.notification}
                  </Typography>
                </Box>

                {/* Time Ago */}
                {/* <Typography style={{ ...headerText("sub") }}>
                  {ISOtoDDMMYY(notify?.added_on)}
                </Typography> */}
              </Box>
            ))}
          </Box>
        ))}
    </Box>
  );
};
export default AllTab;

// --------- stylings ---------
const headerText = (type) => {
  return {
    color: "#9A9A9A",
    fontWeight: type === "main" ? 600 : 400,
    fontSize: "12px",
    lineHeight: "16px",
  };
};

const notifyText = () => {
  return {
    color: "#242424",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  };
};

// -------------------------------

// --------- temp data ---------

// -------------------------------
