import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { ListItem } from "./EsgGptCompanyList";
import { Checkbox, List, ListItemText, Radio } from "@material-ui/core";
import CustomButton from "../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, viewOrganizationEsgGpt } from "../../../redux/actions/gpt/esgGptAction";



const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function EsgGptFilterMenu({ anchorEl, handleClose }) {
  const classes = useStyles();

  const { token } = useSelector((state) => state.login);

  const dispatch = useDispatch();

  const {
    filterData,
    ...all
  } = useSelector((state) => state.esgGptReducer);


  const [filters, setFilters] = React.useState(filterData);



  const [selectedFiltered, setSelectedFiltered] = React.useState(filterData[0]);

  //   const [filteredOptions, ]

  React.useEffect(() => {
    setSelectedFiltered(filterData[0]);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleNumberSelect = (numbers, selectedNumber) => {

    // Check if the selected number is already in the array
    const numberIndex = numbers.indexOf(selectedNumber);

    if (numberIndex === -1) {
      // If not present, add it to the array
      //   setNumbers([...numbers, selectedNumber]);
      return selectedNumber === 'ALL' ? ['ALL','IN', 'GB', 'US', 'NL'] : [...numbers, selectedNumber];
    } else {
      // If present, remove it from the array
      const updatedNumbers = [...numbers];
      updatedNumbers.splice(numberIndex, 1);

      return selectedNumber === 'ALL' ? [] : updatedNumbers;
      //   setNumbers(updatedNumbers);
    }
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ width: "25vw" }}>
          {/* top */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
              borderBottom: "1px solid #C0D4E9",
            }}
          >
            <Typography
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              Filter Options
            </Typography>
            <Typography
              onClick={handleClose}
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              <CloseIcon style={{ cursor: "pointer", color: "#3374b9" }} />
            </Typography>
          </div>
          {/* body */}
          <div style={{ width: "100%", height: "35vh", display: "flex" }}>
            <div
              style={{
                width: "35%",
                height: "100%",
                borderRight: "1px solid #C0D4E9",
                padding: "10px",
              }}
            >
              <List
                component="nav"
                aria-label="main mailbox folders"
                style={{ height: "100%" }}
              >
                {filters.map((data) => (
                  <ListItem
                    key={data?.id}
                    button
                    selected={data?.id === selectedFiltered?.id}
                    onClick={() => {
                      setSelectedFiltered(data);
                      //   setSelectedOption(1);
                    }}
                    style={{ borderRadius: "8px", marginBottom: "5px" }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontSize: 13,
                            fontWeight: 600,
                            color: "#15314E",
                          }}
                        >
                          {data.type}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </div>

            <div style={{ width: "65%", height: "100%", padding: "10px" }}>
              <List
                component="nav"
                aria-label="main mailbox folders"
                style={{ height: "100%", overflow: "scroll" }}
              >
                {selectedFiltered?.options.map((option) => (
                  <ListItem
                    key={option?.id}
                    button
                    selected={
                      selectedFiltered?.id === 3
                        ? selectedFiltered?.selectOption.some(
                            (i) => i === option?.id
                          )
                        : option?.id === selectedFiltered?.selectOption
                    }
                    onClick={() => {
                      setSelectedFiltered((pre) => ({
                        ...pre,
                        selectOption:
                          selectedFiltered?.id === 3
                            ? handleNumberSelect(
                                selectedFiltered?.selectOption,
                                option?.id
                              )
                            : option?.id,
                      }));
                      setFilters((pre) =>
                        pre.map((d) =>
                          d.id === selectedFiltered?.id
                            ? {
                                ...d,
                                selectOption:
                                  selectedFiltered?.id === 3
                                    ? handleNumberSelect(
                                        selectedFiltered?.selectOption,
                                        option?.id
                                      )
                                    : option?.id,
                              }
                            : d
                        )
                      );
                    }}
                    style={{ borderRadius: "8px", marginBottom: "5px" }}
                  >
                    <ListItemText
                      primary={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          {selectedFiltered?.id === 3 ? (
                            <Checkbox
                              size="small"
                              color="primary"
                              style={{ padding: "0px", marginRight: "10px" }}
                              checked={selectedFiltered?.selectOption.some(
                                (i) => i === option?.id
                              )}
                            />
                          ) : (
                            <Radio
                              color="primary"
                              size="small"
                              checked={
                                option?.id === selectedFiltered?.selectOption
                              }
                              style={{ padding: "0px", marginRight: "10px" }}
                            />
                          )}
                          <Typography
                            style={{
                              fontSize: 13,
                              fontWeight: 600,
                              color: "#15314E",
                            }}
                          >
                            {option.name}
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>

          {/* action buttons */}
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "35%", padding: "10px" }}>
              <CustomButton
                variant="text"
                color="primary"
                onClick={handleClose}
                fullWidth
              >
                clear
              </CustomButton>
            </div>
            <div style={{ width: "65%", padding: "10px" }}>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(
                    viewOrganizationEsgGpt(
                      token,
                      10,
                      0,
                      "",
                      filters[0]?.selectOption,
                      filters[1]?.selectOption,
                      filters[2]?.selectOption,
                      true
                    )
                  );
                  dispatch(setFilter(filters))
                  handleClose();
                }}
                fullWidth
              >
                Apply Changes
              </CustomButton>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
