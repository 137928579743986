import Typography from "@material-ui/core/Typography";

import signupCardUseStyles from "../../../screen/signup/signupCardStyles";

// icon
import { FaCheck } from "react-icons/fa6";

const CheckMark = (details) => {
  // styles
  const classes = signupCardUseStyles();

  return (
    <div style={{ display: "flex", padding: "5px 0px", alignItems: "center" }}>
      <FaCheck color="#32A05F" />
      <Typography className={classes.sideBarSubTitle}>
        {details || ""}
      </Typography>
    </div>
  );
};
export default CheckMark;
