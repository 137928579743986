export const SET_ELASTIC_ORGANIZATIONS = "GET_ELASTIC_ORGANIZATIONS";
export const SET_COMPANY_DATA = "GET_COMPANY_DATA";
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const SET_TOTAL_COMPANIES = "SET_TOTAL_COMPANIES";
export const SET_CONTROVERSY_LINKS = "SET_CONTROVERSY_LINKS";
export const SET_PAGINATION_PAGE = "SET_PAGINATION_PAGE";
export const SET_SURVEY_DATA = "SET_SURVEY_DATA";
export const SET_SELECTED_SURVEY = "SET_SELECTED_SURVEY";
export const SET_SURVEY_PAGINATION_PAGE = "SET_SURVEY_PAGINATION_PAGE";
export const SET_SELECTED_SURVEY_RESPONSE = "SET_SELECTED_SURVEY_RESPONSE";
export const SET_SELECTED_RES_COMPANY = "SET_SELECTED_RES_COMPANY";
export const SET_SECTION = "SET_SECTION";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_SELECTED_SECTION ="SET_SELECTED_SECTION"

