import { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";

import CheckMark from "../../../../CommonComps/CheckMarkCards";

// icons
import { FiCalendar } from "react-icons/fi";
import { FaIndianRupeeSign } from "react-icons/fa6";
import InvoiceTable from "../../../../CommonComps/InvoiceTable";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDetails } from "../../../../../../../redux/actions/gpt/paymentAction";
import ISOtoDDMMYY from "../../../../../../../utils/dateConverters/ISOtoDDMMYY";

const PlansTab = () => {
  // from redux
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const { invoiceDetails } = useSelector((state) => state.esgGptReducer);

  const is_premium = JSON.parse(localStorage.getItem("is_premium"));

  // -------- extra data --------
  const invoiceCardsData = [
    {
      Icon: FiCalendar,
      subText: "Next Invoice issue date",
      mainText: invoiceDetails?.next_issue_info?.next_issue_date
        ? ISOtoDDMMYY(invoiceDetails?.next_issue_info?.next_issue_date)
        : "N/A",
    },
    {
      Icon: FaIndianRupeeSign,
      subText: "Invoice total amount",
      mainText: `₹ ${invoiceDetails?.next_issue_info?.last_amount_paid}`,
    },
    {
      subText: "Invoice sent to",
      mainText: invoiceDetails?.next_issue_info?.customer_email,
    },
  ];
  // ----------------------------

  useEffect(() => {
    dispatch(getInvoiceDetails(token));
  }, []);

  return (
    <Box width={"100%"} height={"100%"} padding={1}>
      <Typography
        style={{
          borderBottom: "1px solid #EBF1F8",
          paddingBottom: 20,
          marginBottom: 20,
          ...mainText(),
        }}
      >
        Plan Details
      </Typography>

      {/* main box */}
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        style={{ gap: 50 }}
      >
        {/* Current Plan Box */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ gap: 10, borderRadius: 8 }}
        >
          <Typography style={{ ...contentTitle() }}>
            {is_premium ? "Plus Plan" : "Free Plan"} (Current plan)
          </Typography>
          <Typography style={{ ...contentPricing() }}>
            ₹ {is_premium ? "1000/month" : "0"}
          </Typography>

          <Box>
            {is_premium
              ? [
                CheckMark("2 Years of Sustainability Data"),
                CheckMark("500 prompts/month"),
                CheckMark("500+ Unlocked Company Profiles"),
                CheckMark("Company Peer analysis"),
                CheckMark("Company Sentiment & Controversy Profile"),
                CheckMark("General ESG Chat"),
                ]
              : [
                  CheckMark("2 Years of Sustainability Data"),
                  CheckMark("50 prompts/month"),
                  CheckMark("Locked Company Profiles"),
                  CheckMark("General ESG Chat"),
                ]}
          </Box>
        </Box>

        {/* Invoice Details Box */}
        <Box>
          <Typography
            style={{
              borderBottom: "1px solid #EBF1F8",
              paddingBottom: 20,
              marginBottom: 10,
              ...mainText(),
            }}
          >
            Invoice Details
          </Typography>

          {/* cards and table */}
          <Box>
            {/* cards */}
            <Box
              border={"1px solid #EBF1F8"}
              padding={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              {/* next invoice data */}

              {invoiceCardsData.map((e, index) => (
                <Box
                  key={index}
                  display={"flex"}
                  justifyContent={"space-between"}
                  maxWidth={"16em"}
                  width={"100%"}
                >
                  {e.Icon && (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      bgcolor={"#EBF1F8"}
                      borderRadius={"8px"}
                      width={"3em"}
                      height={"3em"}
                    >
                      <e.Icon color="#3374B9" size={25} />
                    </Box>
                  )}

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ gap: 5 }}
                  >
                    <Typography style={{ ...cardsSub() }}>
                      {e.subText}
                    </Typography>
                    <Typography
                      style={{
                        ...cardsMain(),
                        wordWrap: "break-word",
                        maxWidth: "13.5em",
                      }}
                    >
                      {e.mainText}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Invoice Table */}
            {invoiceDetails?.invoice_list?.length !== 0 && (
              <InvoiceTable invoiceDetails={invoiceDetails} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default PlansTab;

// --------- stylings ---------
const mainText = () => {
  return {
    color: "#15314E",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

const contentTitle = () => {
  return {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "28px",
    color: "#242424",
  };
};

const contentPricing = () => {
  return {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#9A9A9A",
  };
};

const cardsSub = () => {
  return {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#878787",
  };
};

const cardsMain = () => {
  return {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#242424",
  };
};
// -----------------------------
