import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { uaFetchInvoiceDetails } from "../../redux/actions/gpt/paymentAction";
import CompletePaymentMain from "../../components/screen/completePayment/completePaymentMain";
import { useEffect } from "react";

const PaymentCont = () => {
  const history = useHistory();
  const { location } = history;

  const params = new URLSearchParams(location.search);
  const invoice_id = params.get("invoice");

  if (!invoice_id) {
    window.location.href = "/";
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (invoice_id) {
      // fetch invoice details
      dispatch(uaFetchInvoiceDetails(invoice_id));
    }
  }, [invoice_id]);

  return <CompletePaymentMain />;
};
export default PaymentCont;
