import { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import tick from "../../../images/tick.png";
import logoWhite from "../../../images/ImpactGrows White Corrected.svg";

import signupStyles from "../signup/signupStyles";
import LeftNotShrink from "./components/LeftNotShrink";
import LeftShrink from "./components/LeftShrink";
import "../signup/signup.css";

import ResetPassword from "../../widgets/resetPasswordUIs/ResetPassword";

const ResetPasswordMain = ({ reset_code, email_id }) => {
  // from redux

  const classes = signupStyles();

  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShrink(true);
    }, 500);

    return () => {
      setShrink(false);
    };
  }, []);

  const subCard = (details) => {
    return (
      <div style={{ display: "flex", padding: "10px 0px" }}>
        <img alt="" src={tick} width="20px" height="20px" />
        <Typography className={classes.sideBarSubTitle}>
          {details || ""}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.mainDiv}>
      {/* main ImGrows logo */}
      <div className={classes.mainDiv1}>
        <img src={logoWhite} alt="logo" />
      </div>

      <Grid container lassName={classes.mainGrid}>
        {/* Left Side */}
        <Grid
          container
          item
          className={
            shrink
              ? `blueDivShrinked ${shrink ? "blueDivAnimation" : ""}`
              : `blueDiv ${shrink ? "blueDivAnimation" : ""}`
          }
          style={{ zIndex: "2" }}
        >
          {!shrink && <LeftNotShrink classes={classes} subCard={subCard} />}
          {shrink && <LeftShrink classes={classes} subCard={subCard} />}
        </Grid>

        {/* Right Side */}
        <Grid item container justifyContent="center" alignItems="center" xs={9}>
          <Grid item xs={7} sm={7} md={7}>
            {shrink && (
              <ResetPassword reset_code={reset_code} email_id={email_id} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default ResetPasswordMain;
