import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";

// component imports
import CustomButton from "../../../../../UI/button/button";

// redux imports
import { useDispatch } from "react-redux";
import { setDisclaimer } from "../../../../../../redux/actions/gpt/esgGptAction";

const DisclaimerDialog = ({ isDisclaimer }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={isDisclaimer} style={{ minW: "100%" }}>
      <Typography
        style={{
          padding: "10px 10px",
          fontSize: 14,
          fontWeight: 600,
          borderBottom: "1px solid #AFAFAF",
        }}
      >
        Disclaimer
      </Typography>
      <DialogContent>
        <div componet={Paper}>
          <div style={{ padding: "30px 0px" }}>
            <Typography
              style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}
            >
              All the information on this app is published in good faith and for
              general information purpose only. Imgrows do not make any
              warranties about the completeness, reliability and accuracy of
              this information.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              paddingBottom: "5px",
            }}
          >
            <CustomButton
              onClick={() => {
                localStorage.setItem("isDisclaimer", false);
                dispatch(setDisclaimer(false));
              }}
              style={{
                textTransform: "none",
                padding: "3px 18px",
                borderRadius: "8px",
                fontSize: 13,
                "&:hover": {
                  boxShadow: "1px 1px 4px grey",
                },
              }}
              color="primary"
              variant="contained"
            >
              Continue
            </CustomButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DisclaimerDialog;
